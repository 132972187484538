import React from 'react';

import Icon from './Icon';

export default function StrokeAddCircleIcon({
  className = '',
  text = 'Add',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 5.91667C10.4142 5.91667 10.75 6.25245 10.75 6.66667V13.3333C10.75 13.7475 10.4142 14.0833 10 14.0833C9.58579 14.0833 9.25 13.7475 9.25 13.3333V6.66667C9.25 6.25245 9.58579 5.91667 10 5.91667Z"
          fill="#616C77"
        />
        <path
          d="M5.91667 10C5.91667 9.58579 6.25245 9.25 6.66667 9.25H13.3333C13.7475 9.25 14.0833 9.58579 14.0833 10C14.0833 10.4142 13.7475 10.75 13.3333 10.75H6.66667C6.25245 10.75 5.91667 10.4142 5.91667 10Z"
          fill="#616C77"
        />
        <path
          d="M10 2.75C5.99555 2.75 2.75 5.99555 2.75 10C2.75 14.0045 5.99555 17.25 10 17.25C14.0045 17.25 17.25 14.0045 17.25 10C17.25 5.99555 14.0045 2.75 10 2.75ZM1.25 10C1.25 5.16712 5.16712 1.25 10 1.25C14.8329 1.25 18.75 5.16712 18.75 10C18.75 14.8329 14.8329 18.75 10 18.75C5.16712 18.75 1.25 14.8329 1.25 10Z"
          fill="#616C77"
        />
      </svg>
    </Icon>
  );
}

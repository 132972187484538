import { useCallback, useEffect, useMemo, useState } from 'react';

import { convertQueryToRxDBQuery } from '../filters-compiler/convertQueryToRxDBQuery';
import { parseFromQuery } from '../filters-compiler/parser';
import { FeedDocumentLocation, FilteredView, FirstClassDocument, SplitByKey } from '../types';
import nowTimestamp from '../utils/dates/nowTimestamp';
import { safeDecodeURIComponent } from '../utils/safeDecodeURIComponent';
import { getListId, SplitBySeenValues } from './models';
import { useCurrentSortRule } from './stateHooks';

/*
  The purpose of this hook is to unify the logic for creating a mango query for a view or a filter.
  NOTE: A default limit is not added here to prevent errors in bulk actions.
  Ensure a default limit is added to the returned query before using it in a list.
*/

interface Props {
  view?: FilteredView;
  query?: string;
  splitByUrlParam?: SplitByKey;
  splittingBy?: string;
  splitValue?: string;
}

export default function useFilteredViewMangoQuery({
  view,
  query,
  splitByUrlParam,
  splittingBy,
  splitValue,
}: Props) {
  const _query = view?.query || query || '';

  const decodedQuery = useMemo(() => safeDecodeURIComponent(_query), [_query]);

  const { ast: filterAst, errorMessage: parserErrorMessage } = useMemo(() => {
    return parseFromQuery(decodedQuery);
  }, [decodedQuery]);

  const splitBy = view?.splitBy ?? splitByUrlParam ?? splittingBy;
  const isSplitBySeen = splitBy === SplitByKey.Seen;

  const filterBySeenOrUnseen = useMemo(() => {
    if (!isSplitBySeen) {
      return undefined;
    }

    if (splitValue === SplitBySeenValues.seen.queryParamValue) {
      return FeedDocumentLocation.Seen;
    }

    return FeedDocumentLocation.New;
  }, [splitValue, isSplitBySeen]);

  const listId = getListId({
    filter: filterAst ? { ast: filterAst, query: decodedQuery } : undefined,
    splitByKey: splitByUrlParam,
    splitByValue: isSplitBySeen ? undefined : splitValue,
    filterByFeedDocumentLocation: filterBySeenOrUnseen,
  });

  const sortRules = view?.sortRules;
  const sortRulesKey = view ? undefined : 'filterPage';
  const currentSortRule = useCurrentSortRule({ sortRules, sortRulesKey, listId });
  const [currentTimestamp, setCurrentTimestamp] = useState(nowTimestamp());

  useEffect(() => {
    const newTimestamp = nowTimestamp();
    if (splitBy === SplitByKey.Seen && Math.abs(currentTimestamp - newTimestamp) > 500) {
      // TODO: prevent this running when going from home to view or vice versa
      setCurrentTimestamp(newTimestamp);
    }
  }, [currentTimestamp, splitBy, splitValue]);

  const {
    mangoQuery: convertedMangoQuery,
    databaseHookOptions,
    errorMessage: converterErrorMessage,
  } = useMemo(
    () =>
      convertQueryToRxDBQuery<FirstClassDocument>({
        query: decodedQuery,
        sortRules: [currentSortRule],
        splitBy: splitBy ? (splitBy as SplitByKey) : undefined,
        splitByValue: splitValue,
        seenStatusChangedAtThreshold: currentTimestamp - 1,
      }),
    [decodedQuery, currentSortRule, splitBy, splitValue, currentTimestamp],
  );

  const errorMessage = useMemo(
    () => parserErrorMessage || converterErrorMessage,
    [parserErrorMessage, converterErrorMessage],
  );

  const mangoQuery = useMemo(
    () => errorMessage ? undefined : convertedMangoQuery,
    [convertedMangoQuery, errorMessage],
  );

  const createMangoQuery = useCallback(() => mangoQuery, [mangoQuery]);

  return {
    mangoQuery,
    createMangoQuery,
    listId,
    parserErrorMessage: errorMessage,
    currentSortRule,
    databaseHookOptions,
  };
}

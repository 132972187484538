import { AnyDocument } from '../../types';
import getServerBaseUrl from '../../utils/getServerBaseUrl.platform';
import requestWithAuth from '../../utils/requestWithAuth.platformIncludingExtension';
import { createToast } from '../toasts.platform';

export const sendToKindle = async (docId: AnyDocument['id']) => {
  try {
    const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/send_to_kindle/${docId}`, {
      credentials: 'include',
      method: 'POST',
      mode: 'cors',
    });

    if (!resp.ok) {
      throw new Error('Failed to send to Kindle');
    }

    const data = await resp.json();

    if (data.status === 'already_sent') {
      createToast({
        content: 'This document was already sent to Kindle',
        category: 'info',
        duration: 5000,
      });
    } else {
      createToast({
        content: 'Sent to Kindle successfully',
        category: 'success',
        duration: 5000,
      });
    }
  } catch (error) {
    createToast({
      content: 'Failed to send to Kindle',
      category: 'error',
      duration: 5000,
    });
  }
};

export const sendDigestToKindle = async () => {
  const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/send_kindle_digest/`, {
    credentials: 'include',
    method: 'POST',
    mode: 'cors',
  });
  if (!resp.ok) {
    createToast({
      content: 'Failed to send digest to Kindle',
      category: 'error',
      duration: 5000,
    });
  } else {
    createToast({
      content: 'Sent digest to Kindle successfully',
      category: 'success',
      duration: 5000,
    });
  }
};

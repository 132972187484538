import React from 'react';

import Icon from './Icon';

export default function ViewAsTextIcon({
  className = '',
  text = 'Tag',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3.25 5.5C3.25 3.70507 4.70507 2.25 6.5 2.25H13.5C15.2949 2.25 16.75 3.70507 16.75 5.5V14.5C16.75 16.2949 15.2949 17.75 13.5 17.75H6.5C4.70507 17.75 3.25 16.2949 3.25 14.5V5.5ZM6.5 3.75C5.5335 3.75 4.75 4.5335 4.75 5.5V14.5C4.75 15.4665 5.5335 16.25 6.5 16.25H13.5C14.4665 16.25 15.25 15.4665 15.25 14.5V5.5C15.25 4.5335 14.4665 3.75 13.5 3.75H6.5Z" />
        <path d="M6.25 7C6.25 6.58579 6.58579 6.25 7 6.25H13C13.4142 6.25 13.75 6.58579 13.75 7C13.75 7.41421 13.4142 7.75 13 7.75H7C6.58579 7.75 6.25 7.41421 6.25 7Z" />
        <path d="M6.25 10C6.25 9.58579 6.58579 9.25 7 9.25H13C13.4142 9.25 13.75 9.58579 13.75 10C13.75 10.4142 13.4142 10.75 13 10.75H7C6.58579 10.75 6.25 10.4142 6.25 10Z" />
        <path d="M6.25 13C6.25 12.5858 6.58579 12.25 7 12.25H11C11.4142 12.25 11.75 12.5858 11.75 13C11.75 13.4142 11.4142 13.75 11 13.75H7C6.58579 13.75 6.25 13.4142 6.25 13Z" />
      </svg>
    </Icon>
  );
}

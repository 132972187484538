import { useCallback, useEffect, useMemo, useState } from 'react';

import { type AnyDocument, type FirstClassDocument, type PartialDocument, Category } from '../../types';
import {
  type DocumentChunk,
  type DocumentChunkContent,
  type DocumentChunkContentMap,
  type DocumentChunkTextContent,
  type WebviewDocumentChunk,
  DocumentChunkType,
} from '../../types/chunkedDocuments';
import { isArticle, isFirstClassDocument, notEmpty } from '../../typeValidators';
import exceptionHandler from '../../utils/exceptionHandler.platform';
import getUrlDomain from '../../utils/getUrlDomain';
import makeLogger from '../../utils/makeLogger';
import { fetchRelatedRSS } from '../methods';
import { fetchDocumentContent } from '../stateUpdaters/transientStateUpdaters/documentContent';
import { arrayBufferToUTF8String, base64ToArrayBuffer } from '../utils/bytes';
import { createDataUrl } from '../utils/createDataUrl';
import { decryptData } from '../utils/drm';
import { extractTopLevelNodeIndex } from '../utils/locationSerialization/utils';
import useStatePlusLiveValueRef from '../utils/useStatePlusLiveValueRef';
import { usePartialDocument } from './index';

const logger = makeLogger(__filename);

function decodeChunkContent(chunk: DocumentChunk, data: ArrayBuffer): DocumentChunkContent | undefined {
  switch (chunk.type) {
    case DocumentChunkType.Style:
    case DocumentChunkType.Script:
    case DocumentChunkType.Navigation:
    case DocumentChunkType.Document:
    case DocumentChunkType.Smil:
      return {
        type: chunk.type,
        id: chunk.internal_id,
        text: arrayBufferToUTF8String(data),
        data: undefined,
      };
    case DocumentChunkType.Image:
    case DocumentChunkType.Unknown:
    case DocumentChunkType.Vector:
    case DocumentChunkType.Font:
    case DocumentChunkType.Video:
    case DocumentChunkType.Audio:
    case DocumentChunkType.Cover:
      return {
        type: chunk.type,
        id: chunk.internal_id,
        text: undefined,
        data,
      };
  }
}

async function unpackChunk(chunk: DocumentChunk): Promise<DocumentChunkContent | undefined> {
  logger.debug('unpacking chunk', { id: chunk.internal_id, type: chunk.type });
  const data = base64ToArrayBuffer(chunk.data);
  if (!chunk.is_encrypted) {
    return decodeChunkContent(chunk, data);
  }
  if (!chunk.password) {
    exceptionHandler.captureException('Chunk is encrypted but no password', {
      extra: {
        chunk,
      },
    });
    return undefined;
  }
  const decryptedData = await decryptData(data, chunk.password);
  return decodeChunkContent(chunk, decryptedData);
}

/**
 * Loads set of chunks from disk and decrypts them if necessary, returning a map from chunk ID to chunk content.
 */
function useDocumentChunkContentMap(
  doc: PartialDocument<FirstClassDocument, 'id' | 'transientData'> | null,
  chunkIds: string[],
): DocumentChunkContentMap {
  const [chunkContentMap, setChunkContentMap, chunkContentMapRef] =
    useStatePlusLiveValueRef<DocumentChunkContentMap>({});
  const chunkMap = doc?.transientData.chunks;
  const filenameToChunkId = useMemo(
    () =>
      Object.fromEntries(
        Object.values(chunkMap ?? {}).map((chunk) => [chunk.filename, chunk.internal_id]),
      ),
    [chunkMap],
  );
  const chunksToUnpack = useMemo(() => {
    if (!chunkMap) {
      return [];
    }
    return chunkIds
      .map((chunkId) => {
        const chunk = chunkMap[chunkId];
        if (!chunk) {
          logger.warn('Could not find chunk with ID', { chunkId });
          return undefined;
        }
        return chunk;
      })
      .filter(notEmpty);
  }, [chunkIds, chunkMap]);

  const inlineFileChunks = useCallback(
    async (html: string) => {
      // TODO: handle file types other than images i.e. inline styles, scripts, audio, video as well.
      const sourcesToReplace = html.matchAll(/src="([\w.]+)"/gi);
      const promises = Array.from(sourcesToReplace, async (source) => {
        const filename = source[1];
        const chunkId = filenameToChunkId[filename];
        const chunk = chunkMap?.[chunkId];
        if (!chunk) {
          logger.warn('Could not find chunk with ID', { chunkId });
          return undefined;
        }
        const fileContent = await unpackChunk(chunk);
        if (!fileContent?.data) {
          logger.warn('Chunk has no data', { chunkId });
          return undefined;
        }
        return [filename, createDataUrl(filename, fileContent.data)];
      });
      const dataUrls = (await Promise.all(promises)).filter(notEmpty);
      let inlinedHtml = html;
      for (const [filename, dataUrl] of dataUrls) {
        inlinedHtml = inlinedHtml.replaceAll(`"${filename}"`, `"${dataUrl}"`);
      }
      return inlinedHtml;
    },
    [chunkMap, filenameToChunkId],
  );

  // NOTE: this useEffect can be expensive because it might decrypt many chunks, on the main thread.
  //   therefore, ensure it is not run too often lest we degrade app performance.
  useEffect(() => {
    (async () => {
      const unprocessedChunks = chunksToUnpack.filter(
        (chunk) => !(chunk.internal_id in chunkContentMapRef.current),
      );
      const entries = await Promise.all(
        unprocessedChunks.map(async (chunk) => {
          const chunkContent = await unpackChunk(chunk);
          if (!chunkContent) {
            return undefined;
          }
          if (chunkContent.type === DocumentChunkType.Document) {
            chunkContent.text = await inlineFileChunks(chunkContent.text);
          }
          logger.debug('unpacked chunk content', {
            id: chunk.internal_id,
            size: chunkContent.text?.length,
          });
          return chunkContent as DocumentChunkTextContent;
        }),
      );
      const contentMapEntriesToAdd = Object.fromEntries(
        entries.filter(notEmpty).map((chunk) => [chunk.id, chunk]),
      );
      setChunkContentMap((existingEntries) => ({
        ...existingEntries,
        ...contentMapEntriesToAdd,
      }));
    })();
    // we use chunkContentMapRef here instead of chunkContentMap itself to prevent an infinite render loop.
  }, [chunkContentMapRef, chunksToUnpack, inlineFileChunks, setChunkContentMap]);
  return chunkContentMap;
}

function useStartChunkIndex(
  doc: PartialDocument<AnyDocument, 'readingPosition'> | null,
  chunkArray: DocumentChunk[] | null,
): number | undefined {
  return useMemo(() => {
    if (!doc) {
      return undefined;
    }
    const position = doc.readingPosition?.serializedPosition;
    if (!position) {
      return 0;
    }
    let topLevelNodeIndex = extractTopLevelNodeIndex(position);
    if (topLevelNodeIndex === undefined) {
      return undefined;
    }
    if (!chunkArray) {
      return undefined;
    }
    let chunkIndex = 0;
    for (const chunk of chunkArray) {
      if (chunk.html_child_node_count === null) {
        exceptionHandler.captureException('chunk is missing top level node count', {
          extra: {
            chunk,
            chunkIndex,
            topLevelNodeIndex,
            position,
          },
        });
        break;
      }
      if (topLevelNodeIndex < chunk.html_child_node_count) {
        break;
      }
      topLevelNodeIndex -= chunk.html_child_node_count;
      chunkIndex += 1;
    }
    return chunkIndex;
  }, [chunkArray, doc]);
}

/**
 * Loads and decrypts 'window' of chunks around the last reading position of the given doc.
 * Chunks are guaranteed to be in order and have images inlined as base64 data URLs.
 *
 * For compatibility, if document is not chunked, returns a one-chunk array with full document content.
 *
 * @param docId
 *
 * @return Object array of chunk contents, plus a function to load or unload a chunk with a given ID.
 */
export function useChunkedDocumentContent(docId: string | undefined) {
  const [doc, { isFetching }] = usePartialDocument(
    docId,
    ['id', 'readingPosition', 'category', 'html', 'content', 'url', 'parsed_doc_id', 'transientData'],
    {
      shouldPollStateIfMissing: true,
    },
  );

  // TODO: this code is duplicated from useDocumentContentFromState().
  //   eventually that hook code should be removed and any uses of it should use useChunkedDocumentContent() instead.
  const unchunkedContent = useMemo(() => {
    if (!doc) {
      return null;
    }
    return doc.category === Category.Highlight ? doc.html : doc.transientData.content;
  }, [doc]);

  const hasDoc = Boolean(doc);
  const parsedDocId = doc && isFirstClassDocument(doc) && doc?.parsed_doc_id;
  const articleDomain = doc && isArticle(doc) && getUrlDomain(doc.url);

  useEffect(() => {
    if (!docId || !hasDoc) {
      return;
    }

    /*
      This is async. It isn't guaranteed the content will be there by the time this function returns.
      This is here to ensure the content is requested whenever we're trying to use it. It's called
      elsewhere too (to pre/load the content) but let's be safe. Even if the other calls are
      accidentally broken / removed, this will save us.
    */
    fetchDocumentContent([docId]);
    if (articleDomain) {
      fetchRelatedRSS([articleDomain]);
    }
  }, [docId, hasDoc, parsedDocId, articleDomain]);

  const spine = useMemo(
    () =>
      doc?.transientData.spine &&
      Object.entries(doc.transientData.spine)
        .map(([index, id]) => [Number.parseInt(index, 10), id] as [number, string])
        .sort(([idx1, _id1], [idx2, _id2]) => idx1 - idx2)
        .map(([_, id]) => id),
    [doc?.transientData.spine],
  );
  const chunkMap = doc?.transientData.chunks;
  const chunkArray = useMemo(() => {
    if (!spine || !chunkMap) {
      return null;
    }
    const chunks = spine.map((id) => chunkMap[id]);
    if (!chunks.every(notEmpty)) {
      return null;
    }
    return chunks;
  }, [chunkMap, spine]);

  const startChunkIndex = useStartChunkIndex(doc, chunkArray);
  const chunkIndexesToLoad = useMemo(() => {
    if (!startChunkIndex) {
      return undefined;
    }
    return Array.from({ length: 3 }, (_, index) => startChunkIndex - 1 + index).filter(
      (index) => index >= 0,
    );
  }, [startChunkIndex]);

  const [loadedChunkIds, setLoadedChunkIds] = useState<{
    [id: string]: boolean;
  }>({});

  useEffect(() => {
    logger.debug('clearing loaded chunks');
    setLoadedChunkIds({});
  }, [docId]);

  const loadedChunkIdsArray = useMemo(() => Object.keys(loadedChunkIds), [loadedChunkIds]);
  const chunkContentMap = useDocumentChunkContentMap(doc, loadedChunkIdsArray);

  const setChunkContentState = useCallback((chunkId: string, state: 'loaded' | 'unloaded') => {
    setLoadedChunkIds((ids) => {
      switch (state) {
        case 'loaded':
          if (chunkId in ids) {
            return ids;
          }
          logger.debug(`chunk content from store LOAD`, { id: chunkId });
          return {
            ...ids,
            [chunkId]: true,
          };
        case 'unloaded': {
          if (!(chunkId in ids)) {
            return ids;
          }
          logger.debug(`chunk content from store UNLOAD`, { id: chunkId });
          const newIds = { ...ids };
          delete newIds[chunkId];
          return newIds;
        }
      }
    });
  }, []);

  useEffect(() => {
    if (chunkIndexesToLoad === undefined) {
      return;
    }
    const idsToLoad = Object.fromEntries(
      chunkIndexesToLoad
        .map((index) => spine?.[index])
        .filter(notEmpty)
        .map((id) => [id, true]),
    );
    setLoadedChunkIds((ids) => ({
      ...ids,
      ...idsToLoad,
    }));
  }, [chunkIndexesToLoad, spine]);

  const documentChunks: WebviewDocumentChunk[] | undefined = useMemo(
    () =>
      chunkArray
        ?.map((chunk, index) => {
          const id = chunk?.internal_id;
          if (chunk.html_child_node_count === null) {
            exceptionHandler.captureException(
              'generating WebviewDocumentChunk: text chunk has no html_child_node_count',
              {
                extra: {
                  id,
                  index,
                  chunkType: chunk.type,
                  dataLength: chunk.data.length,
                },
              },
            );
            return undefined;
          }
          return {
            id,
            index,
            content: chunkContentMap?.[id]?.text ?? null,
            html_child_node_count: chunk.html_child_node_count,
          };
        })
        ?.filter(notEmpty),
    [chunkContentMap, chunkArray],
  );

  const chunks: WebviewDocumentChunk[] = useMemo(() => {
    if (documentChunks) {
      return documentChunks;
    }
    if (unchunkedContent) {
      return [
        {
          content: unchunkedContent,
          index: 0,
          // only used for chunked docs, so doesn't need to be accurate.
          html_child_node_count: 0,
          // ID doesn't matter here for single chunk
          id: 'full-content',
        },
      ];
    }
    return [];
  }, [documentChunks, unchunkedContent]);

  useEffect(() => {
    const loaded = documentChunks?.filter((c) => c.content);
    logger.debug(`chunks ${loaded?.length}/${documentChunks?.length} loaded`, {
      loaded: loaded?.map((c) => [c.index, c.id]),
    });
  }, [documentChunks]);

  return useMemo(
    () => ({
      setChunkContentState,
      chunks,
      isFetching,
    }),
    [chunks, isFetching, setChunkContentState],
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import eventEmitter from 'shared/foreground/eventEmitter';
import type { HighlightElement } from 'shared/foreground/types';
import closestWith from 'shared/foreground/utils/closestWith';
import isHighlightNode from 'shared/foreground/utils/isHighlightNode';

import AnnotationPopovers from './AnnotationPopovers';

type Props = {
  containerNodeSelector: string;
};

const defaultExport = React.memo(function SelectionAnnotationPopovers({ containerNodeSelector }) {
  const [isAnnotationBarPopoverShown, setIsAnnotationBarPopoverShown] = useState(false);
  const hideAnnotationBarPopover = useCallback(() => {
    setIsAnnotationBarPopoverShown(false);
  }, []);
  const showAnnotationBarPopover = useCallback(() => {
    setIsAnnotationBarPopoverShown(true);
  }, []);

  const getSelectionDomRect = useCallback(() => {
    const selection = window.getSelection();
    if (!selection?.toString().trim() || !selection.rangeCount) {
      return document.body.getBoundingClientRect();
    }
    const range = selection.getRangeAt(0);
    if (!range) {
      return document.body.getBoundingClientRect();
    }
    const result = range.getBoundingClientRect();
    return result;
  }, []);

  useEffect(() => {
    const container = document.querySelector<HTMLElement>(containerNodeSelector);
    if (!container) {
      return;
    }

    const onPointerUp = () => {
      const selection = window.getSelection();
      if (!selection?.rangeCount || !selection.toString().trim()) {
        return;
      }
      const range = selection.getRangeAt(0);
      if (!range) {
        return;
      }

      // If the selection (start and end) is within the same highlight, ignore because the highlight popover will appear
      const startHighlight = closestWith<HighlightElement>(range.startContainer, (element) =>
        isHighlightNode(element));
      if (startHighlight) {
        const endHighlight = closestWith<HighlightElement>(range.endContainer, (element) =>
          isHighlightNode(element));
        if (startHighlight.dataset.highlightId === endHighlight?.dataset.highlightId) {
          // If the selection start and end nodes are different, the highlight popover doesn't appear on pointer up
          eventEmitter.emit(`annotationPopover-${startHighlight.dataset.highlightId}:show`);
          return;
        }
      }

      setIsAnnotationBarPopoverShown(Boolean(range));
    };
    container.addEventListener('pointerup', onPointerUp);

    const onSelectionChange = () => {
      const selection = window.getSelection();
      if (!selection?.rangeCount || !selection.toString().trim()) {
        hideAnnotationBarPopover();
      }
    };
    document.addEventListener('selectionchange', onSelectionChange);

    return () => {
      document.removeEventListener('selectionchange', onSelectionChange);
      container.removeEventListener('pointerup', onPointerUp);
    };
  }, [containerNodeSelector, hideAnnotationBarPopover]);

  return (
    <AnnotationPopovers
      getBoundingClientRectForMargins={getSelectionDomRect}
      getHighlightBoundingClientRect={getSelectionDomRect}
      hideAnnotationBarPopover={hideAnnotationBarPopover}
      isAnnotationBarPopoverShown={isAnnotationBarPopoverShown}
      shouldShowInMargin={false}
      shouldUseSelection
      showAnnotationBarPopover={showAnnotationBarPopover}
      shouldAutoFocus={false}
    />
  );
}) as React.FC<Props>;

// defaultExport.whyDidYouRender = {
//   trackHooks: true,
//   logOnDifferentValues: true,
// };

export default defaultExport;

import React from 'react';

import Icon from './Icon';

export default function LinkIcon({ text = 'Link' }: { text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.82824 2.17165C7.24245 1.58586 6.2927 1.58586 5.70692 2.17165L4.99981 2.87876C4.80455 3.07402 4.48797 3.07402 4.2927 2.87876C4.09744 2.6835 4.09744 2.36691 4.2927 2.17165L4.99981 1.46454C5.97612 0.488234 7.55903 0.488234 8.53535 1.46454C9.51166 2.44085 9.51166 4.02377 8.53535 5.00008L7.82824 5.70718C7.63298 5.90245 7.31639 5.90245 7.12113 5.70718C6.92587 5.51192 6.92587 5.19534 7.12113 5.00008L7.82824 4.29297C8.41402 3.70718 8.41402 2.75744 7.82824 2.17165ZM2.17138 7.8285C1.5856 7.24272 1.5856 6.29297 2.17138 5.70718L2.87849 5.00008C3.07375 4.80482 3.07375 4.48823 2.87849 4.29297C2.68323 4.09771 2.36665 4.09771 2.17138 4.29297L1.46428 5.00008C0.487966 5.97639 0.487966 7.5593 1.46428 8.53561C2.44059 9.51192 4.0235 9.51192 4.99981 8.53561L5.70692 7.8285C5.90218 7.63324 5.90218 7.31666 5.70692 7.1214C5.51166 6.92614 5.19507 6.92614 4.99981 7.1214L4.2927 7.8285C3.70692 8.41429 2.75717 8.41429 2.17138 7.8285ZM6.76758 3.93942C6.96284 3.74416 6.96284 3.42757 6.76758 3.23231C6.57232 3.03705 6.25573 3.03705 6.06047 3.23231L3.23204 6.06074C3.03678 6.256 3.03678 6.57258 3.23204 6.76784C3.42731 6.96311 3.74389 6.96311 3.93915 6.76784L6.76758 3.93942Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}

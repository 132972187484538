import { AnyDocument } from '../../types';
import { downloadFile } from '../file.platform';
import { createToast } from '../toasts.platform';
import { exportHighlights } from './export';

export const exportHighlightsToFile = async (documentId: AnyDocument['id']): Promise<void> => {
  const md = await exportHighlights(documentId);
  downloadFile('highlights.md', md, 'text/plain');
  createToast({ content: 'Highlights Downloaded', category: 'success' });
};

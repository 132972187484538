import isEqual from 'lodash/isEqual';
import type { MangoQuery } from 'rxdb';

import {
  Article,
  DocumentLocation,
  FirstClassDocument,
  FullZustandState,
  SettingsState,
  UserEvent,
} from '../../types';
import { isDevOrTest } from '../../utils/environment';
import {
  shortListQuery,
  shortListQueryWhenShortlistLocationIsEnabled,
  shortListTag,
} from '../../utils/filteredViews';
import { addTagToDocObject, removeTagFromDocObject } from '../../utils/tagHelpers';
// eslint-disable-next-line import/no-cycle
import database from '../database';
// eslint-disable-next-line import/no-cycle
import { globalState, updateState } from '../models';
// eslint-disable-next-line import/no-cycle
import { updateDocumentLocationOfDoc, updateFilteredViewInState } from '../stateUpdateHelpers';
import { addTag } from '../stateUpdaters/persistentStateUpdaters/documents/tag';
import {
  updateDocument,
  updateDocuments,
} from '../stateUpdaters/persistentStateUpdaters/documents/update';
// eslint-disable-next-line import/no-cycle
import { createToast } from '../toasts.platform';
import type { StateUpdateOptionsWithoutEventName } from '../types';
import { getCategoryUrlArgument } from '../utils/categoryUrlHelpers';
import documentLocationUpdatedEventName from '../utils/documentLocationUpdatedEventName';
import fixBadDocumentLocationsValue from '../utils/fixBadDocumentLocationsValue';
import getUIFriendlyNameForDocumentLocation from '../utils/getUIFriendlyNameForDocumentLocation';
import validDocumentLocationsSettingValues from '../validDocumentLocationsSettingValues';

export const addToShortlist = async (
  {
    id,
    isFeedView,
  }: {
    id: FirstClassDocument['id'];
    isFeedView?: boolean;
  },
  options: StateUpdateOptionsWithoutEventName & { beforeStateUpdate?: () => Promise<void>; },
) => {
  if (
    fixBadDocumentLocationsValue(globalState.getState().persistent.settings.documentLocations).includes(
      DocumentLocation.Shortlist,
    )
  ) {
    await updateDocumentLocation(id, DocumentLocation.Shortlist, options);
    return;
  }

  const updateResult = await addTag(id, shortListTag, options);
  await updateDocumentLocation(id, DocumentLocation.Later, {
    ...options,
    correlationId: updateResult.userEvent?.id,
    dontShowToast: true,
  });
  createToast({
    content: `Added to Shortlist`,
    category: 'success',
    link: `/filter/${shortListQuery}`,
    linkTooltipContent: `Go to shortlist`,
    undoableUserEventId: updateResult.userEvent?.id,
  });
};

type DocumentLocationSettingUpdateDescription = {
  count?: number;
  kind:
    | 'move-from-document-location-to-document-location'
    | 'move-from-shortlist-location-and-add-tag'
    | 'move-to-shortlist-location-and-remove-tag';
  fromDocumentLocation?: DocumentLocation;
  toDocumentLocation?: DocumentLocation;
};

export const runDocumentLocationsUpdateActions = async ({
  isDryRun,
  newDocumentLocations,
  options,
}: {
  isDryRun?: boolean;
  newDocumentLocations: SettingsState['documentLocations'];
  options: StateUpdateOptionsWithoutEventName;
}): Promise<DocumentLocationSettingUpdateDescription[]> => {
  // In order to avoid looping over the documents more than once, we'll gather all modification instructions first
  const updatesToDo: {
    action: (doc: FirstClassDocument) => FirstClassDocument;
    description: DocumentLocationSettingUpdateDescription;
    query: MangoQuery<FirstClassDocument>;
  }[] = [];

  const oldValue = fixBadDocumentLocationsValue(
    globalState.getState().persistent.settings.documentLocations,
  );

  const addedLocations = newDocumentLocations.filter((newLocation) => !oldValue.includes(newLocation));
  const removedLocations = oldValue.filter((oldLocation) => !newDocumentLocations.includes(oldLocation));

  const applyStateUpdates = (state: FullZustandState) => {
    state.persistent.settings.documentLocations = newDocumentLocations;
    if (addedLocations.includes(DocumentLocation.Shortlist)) {
      updateFilteredViewInState({
        shouldErrorIfItDoesntExist: false,
        state,
        updates: {
          query: shortListQueryWhenShortlistLocationIsEnabled,
        },
        viewId: 'shortlist',
      });
    } else if (removedLocations.includes(DocumentLocation.Shortlist)) {
      updateFilteredViewInState({
        shouldErrorIfItDoesntExist: false,
        state,
        updates: {
          query: shortListQuery,
        },
        viewId: 'shortlist',
      });
    }
  };

  if (removedLocations.includes(DocumentLocation.New)) {
    // Move documents from inbox to later
    updatesToDo.push({
      action: (doc) => {
        updateDocumentLocationOfDoc(doc, DocumentLocation.Later, false);
        return doc;
      },
      description: {
        fromDocumentLocation: DocumentLocation.New,
        kind: 'move-from-document-location-to-document-location',
        toDocumentLocation: DocumentLocation.Later,
      },
      query: {
        selector: {
          triage_status: { $eq: DocumentLocation.New },
        },
      },
    });
  }

  if (addedLocations.includes(DocumentLocation.Shortlist)) {
    // Move docs in Later with shortlist tag to Shortlist location and remove shortlist tag
    updatesToDo.push({
      action: (doc) => {
        updateDocumentLocationOfDoc(doc, DocumentLocation.Shortlist, false);
        removeTagFromDocObject(doc, shortListTag);
        return doc;
      },
      description: {
        kind: 'move-to-shortlist-location-and-remove-tag',
      },
      query: {
        selector: {
          triage_status: { $eq: DocumentLocation.Later },
          [`tags.${shortListTag.toLowerCase()}`]: {
            $exists: true,
          },
        },
      },
    });
  } else if (removedLocations.includes(DocumentLocation.Shortlist)) {
    // Add shortlist tag to docs in shortlist location and move those docs to later
    updatesToDo.push({
      action: (doc) => {
        addTagToDocObject(doc, shortListTag);
        updateDocumentLocationOfDoc(doc, DocumentLocation.Later, false);
        return doc;
      },
      description: {
        kind: 'move-from-shortlist-location-and-add-tag',
      },
      query: {
        selector: {
          triage_status: { $eq: DocumentLocation.Shortlist },
        },
      },
    });
  }

  let updateStatePromise: Promise<{ userEvent?: UserEvent; }> | undefined;
  if (!isDryRun) {
    updateStatePromise = updateState(
      (state) => {
        applyStateUpdates(state);
      },
      { ...options, eventName: 'document-locations-setting-updated' },
    );
  }

  const updatePromises = [];
  for (const updateToDo of updatesToDo) {
    if (isDryRun) {
      const promise = database.collections.documents.count(updateToDo.query).then((count) => {
        updateToDo.description.count = count;
      });
      updatePromises.push(promise);
    } else {
      const promise = updateDocuments(updateToDo.query, updateToDo.action, {
        userInteraction: 'tap',
        eventName: updateToDo.description.kind,
        logLevelIfNothingFound: 'off',
        errorMessageIfNothingFound: false,
      });
      updatePromises.push(promise);
    }
  }
  await Promise.all([updateStatePromise, ...updatePromises]);
  return updatesToDo.map(({ description }) => description);
};

export const updateDocumentLocation = async (
  documentId: string,
  newDocumentLocation: DocumentLocation,
  options: StateUpdateOptionsWithoutEventName & {
    beforeStateUpdate?: () => Promise<void>;
    dontShowToastIfSameDocumentLocation?: boolean;
    dontShowToast?: boolean;
  },
): Promise<ReturnType<typeof updateDocument> | undefined> => {
  if (
    isDevOrTest &&
    newDocumentLocation &&
    !fixBadDocumentLocationsValue(globalState.getState().persistent.settings.documentLocations).includes(
      newDocumentLocation,
    )
  ) {
    throw new Error(
      `Can't move document to ${newDocumentLocation} because it's not an enabled location`,
    );
  }

  const doc = await database.collections.documents.findOne(documentId);
  if (!doc) {
    throw new Error("Document doesn't exist in database");
  }

  const newDocumentLocationUIFriendly =
    newDocumentLocation && getUIFriendlyNameForDocumentLocation(newDocumentLocation) || undefined;

  if (doc.triage_status === newDocumentLocation && !options.dontShowToastIfSameDocumentLocation) {
    if (options.userInteraction && !options.dontShowToast) {
      createToast({
        content: `Already in ${newDocumentLocationUIFriendly}`,
        category: 'error',
      });
    }
    return;
  }

  if (options.beforeStateUpdate) {
    await options.beforeStateUpdate();
  }

  const updateResult = await updateDocument<Article>(
    documentId,
    (doc) => {
      updateDocumentLocationOfDoc(doc, newDocumentLocation);
      return doc;
    },
    {
      ...options,
      eventName: documentLocationUpdatedEventName,
    },
  );

  if (newDocumentLocation && options.userInteraction && !options.dontShowToast) {
    createToast({
      content: `Moved to ${newDocumentLocationUIFriendly}`,
      category: 'success',
      link: `/${[getCategoryUrlArgument(), newDocumentLocation].filter(Boolean).join('/')}`,
      linkTooltipContent: `Go to ${newDocumentLocationUIFriendly}`,
      undoableUserEventId: (updateResult.userEvent as UserEvent).id,
    });
  }

  return updateResult;
};

export const updateDocumentLocationsSetting = async (
  newDocumentLocations: SettingsState['documentLocations'],
  options: StateUpdateOptionsWithoutEventName,
) => {
  if (
    !validDocumentLocationsSettingValues.some((validDocumentLocationsConfiguration) =>
      isEqual(newDocumentLocations, validDocumentLocationsConfiguration))
  ) {
    throw new Error('Invalid configuration');
  }

  runDocumentLocationsUpdateActions({
    newDocumentLocations,
    options,
  });
};

import React from 'react';

import Icon from './Icon';

export default function TweetIconHeader({
  className = '',
  text = 'Tweets',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16.6274 7.0798C16.6375 7.22673 16.6375 7.37366 16.6375 7.52194C16.6375 12.0402 13.1979 17.2511 6.90838 17.2511V17.2484C5.05043 17.2511 3.23108 16.7189 1.66699 15.7155C1.93715 15.748 2.20867 15.7642 2.48086 15.7649C4.02057 15.7662 5.51627 15.2496 6.72759 14.2983C5.26439 14.2705 3.9813 13.3165 3.53306 11.9237C4.04562 12.0226 4.57376 12.0023 5.07684 11.8648C3.4816 11.5425 2.33393 10.1409 2.33393 8.51321C2.33393 8.49831 2.33393 8.4841 2.33393 8.46988C2.80925 8.73462 3.34145 8.88155 3.88583 8.8978C2.38336 7.89367 1.92023 5.89489 2.82753 4.33216C4.5636 6.46839 7.12505 7.76705 9.87472 7.9045C9.59915 6.71688 9.97561 5.47238 10.864 4.63752C12.2412 3.34292 14.4072 3.40928 15.7018 4.78581C16.4676 4.63482 17.2016 4.35382 17.8732 3.95569C17.618 4.74721 17.0837 5.41957 16.3701 5.84681C17.0479 5.76692 17.7101 5.58546 18.3337 5.30852C17.8746 5.99645 17.2963 6.59568 16.6274 7.0798Z" />
      </svg>
    </Icon>
  );
}

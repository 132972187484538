import React from 'react';

import Icon from './Icon';

export default function LineSpacing({ className = '' }: { className?: string; }): JSX.Element {
  return (
    <Icon text="LineSpacing">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.99707 3.2463C3.58286 3.2463 3.24707 3.58209 3.24707 3.9963C3.24707 4.41051 3.58286 4.7463 3.99707 4.7463H20.0037C20.4179 4.7463 20.7537 4.41051 20.7537 3.9963C20.7537 3.58209 20.4179 3.2463 20.0037 3.2463H3.99707Z"
          fill="black"
        />
        <path
          d="M3.99707 19.2522C3.58286 19.2522 3.24707 19.5879 3.24707 20.0022C3.24707 20.4164 3.58286 20.7522 3.99707 20.7522H20.0037C20.4179 20.7522 20.7537 20.4164 20.7537 20.0022C20.7537 19.5879 20.4179 19.2522 20.0037 19.2522H3.99707Z"
          fill="black"
        />
        <path
          d="M12.0005 7.24609C12.2914 7.24609 12.5561 7.41434 12.6796 7.67777L16.4312 15.6811C16.607 16.0562 16.4455 16.5027 16.0704 16.6785C15.6954 16.8543 15.2488 16.6928 15.073 16.3178L14.5259 15.1506H9.47516L8.92805 16.3178C8.75224 16.6928 8.30569 16.8543 7.93063 16.6785C7.55558 16.5027 7.39406 16.0562 7.56986 15.6811L11.3214 7.67777C11.4449 7.41434 11.7096 7.24609 12.0005 7.24609ZM10.1783 13.6506H13.8228L12.0005 9.76315L10.1783 13.6506Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
}

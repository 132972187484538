import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MangoQuery } from 'rxdb';
import database from 'shared/foreground/database';
import eventEmitter from 'shared/foreground/eventEmitter';
import { updateFilteredView } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/filteredView';
import { queueJob } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/jobs';
import { createToast } from 'shared/foreground/toasts.platform';
import copyTextToClipboard from 'shared/foreground/utils/copyTextToClipboard';
import { AnyDocument, FilteredView, JobType } from 'shared/types';
import getFormattedDurationFromNow from 'shared/utils/dates/getFormattedDurationFromNow';

import customStyles from './BundleLinkButton.module.css';
import Button from './Button';
import { Dialog } from './Dialog';
import CheckIcon from './icons/CheckIcon';
import LinkIcon from './icons/LinkIcon';
import Popover from './Popovers/Popover';
import styles from './PublicLinkButton.module.css';
import Spinner from './Spinner';

export default function BundleLinkButton({
  view,
  documentIdsQuery,
}: {
  view: FilteredView;
  documentIdsQuery?: MangoQuery<AnyDocument>;
}) {
  const isEnabled = useMemo(() => Boolean(view?.sharedAsBundle), [view?.sharedAsBundle]);
  const bundleUrl = useMemo(() => view?.extraData?.bundleLandingURL, [view]);
  const bundleCoverImageUrl = useMemo(() => view?.extraData?.bundleCoverImageURL, [view]);
  const bundleUpdatedAt = useMemo(() => view?.extraData?.bundleUpdatedAt, [view]);

  const [isPopoverShown, setIsPopoverShown] = useState(false);
  const hidePopover = useCallback(() => setIsPopoverShown(false), []);
  const showPopover = useCallback(() => setIsPopoverShown(true), []);
  const [coverImageURL, setCoverImageURL] = useState(bundleCoverImageUrl);
  const shouldClearCoverImageURL = coverImageURL === bundleCoverImageUrl;
  const [savingStep, setSavingStep] = useState<'saving' | 'saved' | undefined>(undefined);
  const savingStepDisplayName = { saving: 'Saving...', saved: 'Saved' };

  useEffect(() => {
    const onEvent = () => {
      showPopover();
    };
    eventEmitter.on('filter-view-shared-as-bundle-clicked', onEvent);

    return () => {
      eventEmitter.off('filter-view-shared-as-bundle-clicked', onEvent);
    };
  }, [showPopover]);

  const triggerButtonRef = useRef<HTMLButtonElement>();

  const onClickDisable = useCallback(() => {
    setIsDisablePromptShown(true);
    hidePopover();
  }, [hidePopover]);

  const [isDisablePromptShown, setIsDisablePromptShown] = useState(false);

  const onConfirmDisable = useCallback(() => {
    updateFilteredView(
      {
        ...view,
        sharedAsBundle: false,
      },
      { userInteraction: 'click' },
    );
    queueJob({
      jobType: JobType.UpdateBundle,
      jobArguments: { filtered_view_id: view.id, enabled: false, document_ids: [] },
      options: { userInteraction: 'click' },
    });
    setIsDisablePromptShown(false);
  }, [view]);

  const onCancelDisable = useCallback(() => setIsDisablePromptShown(false), []);

  const onClearOrSaveCoverImageURL = useCallback(
    async (event: React.FormEvent<HTMLFormElement> | undefined) => {
      if (event) {
        event.preventDefault();
      }

      if (!shouldClearCoverImageURL && !coverImageURL) {
        createToast({ content: 'Please, enter a cover image URL', category: 'error' });
        return;
      }

      const coverImageToSave = shouldClearCoverImageURL ? '' : coverImageURL;

      if (shouldClearCoverImageURL) {
        setCoverImageURL(coverImageToSave);
      }

      await updateFilteredView(
        {
          ...view,
          sharedAsBundle: true,
          extraData: {
            ...view.extraData,
            bundleCoverImageURL: coverImageToSave,
          },
        },
        { userInteraction: 'click' },
      );

      setSavingStep('saving');

      const savingStepDelay = 1000;

      setTimeout(() => {
        setSavingStep('saved');
        setTimeout(() => {
          setSavingStep(undefined);
        }, savingStepDelay);
      }, savingStepDelay);
    },
    [view, coverImageURL, shouldClearCoverImageURL],
  );

  const refreshBundle = useCallback(async () => {
    const documentIds = await database.collections.documents.findIds({
      ...documentIdsQuery,
      limit: 500,
    });

    queueJob({
      jobType: JobType.UpdateBundle,
      jobArguments: { filtered_view_id: view.id, document_ids: documentIds, enabled: true },
      options: { userInteraction: 'click' },
    });
    createToast({ content: 'Bundle refreshed', category: 'success' });
  }, [view.id, documentIdsQuery]);

  if (!isEnabled) {
    return null;
  }

  let confirmDisableDialog: JSX.Element | null = null;
  if (isDisablePromptShown) {
    confirmDisableDialog =
      <Dialog
        id="disable-bundle-public-link"
        action={onConfirmDisable}
        actionTitle="Disable public link"
        cancelAction={onCancelDisable}
        cancelTitle="Cancel"
        redActionButton
        subtitle="Disabling this public link will prevent anyone from saving this bundle."
        title="Disable public link?"
      />;
  }

  const buttonClasses = [
    styles.publicLinkButton,
    customStyles.bundleLinkButton,
    styles.publicLinkButtonWhenEnabled,
  ];

  return (
    <>
      <Button
        className={buttonClasses.join(' ')}
        onClick={showPopover}
        ref={triggerButtonRef}
        variant="default"
      >
        <LinkIcon text="View public link" />
      </Button>
      <Popover
        className={customStyles.bundleLinkPopover}
        hidePopover={hidePopover}
        isShown={isPopoverShown}
        pointerDownTimeout={100}
        portalDestinationElementId="bundle-shared-public-link-popover"
        reference={triggerButtonRef.current}
        showPopover={showPopover}
      >
        <div className={styles.publicLinkMain}>
          <p className={styles.publicLinkPopoverTitle}>Public link</p>
          <p>Anyone with this link can save your bundle.</p>
          <div className={customStyles.urlHolder}>
            <span className={customStyles.urlLabel}>Cover image URL</span>
            <div className={customStyles.urlFrame}>
              <form onSubmit={onClearOrSaveCoverImageURL}>
                <input
                  autoComplete="off"
                  value={coverImageURL}
                  onChange={(e) => setCoverImageURL(e.target.value)}
                  placeholder="https://"
                  autoFocus={false}
                  pattern="https://.*"
                  type="url"
                />
                <Button
                  className={`${customStyles.copyButton} ${
                    savingStep ? customStyles.isSavingCoverImage : ''
                  }`}
                  disabled={!savingStep && !coverImageURL}
                  type="submit"
                  variant="primary"
                >
                  {savingStep === 'saving' ? <Spinner className={customStyles.savingSpinner} /> : null}
                  {savingStep === 'saved' ? <CheckIcon className={customStyles.savedCheckIcon} /> : null}
                  {savingStep
                    ? savingStepDisplayName[savingStep]
                    : coverImageURL && shouldClearCoverImageURL
                      ? 'Clear'
                      : 'Save'}
                </Button>
              </form>
            </div>
          </div>
          <div className={customStyles.urlHolder}>
            <span className={customStyles.urlLabel}>Public URL</span>
            <div className={customStyles.urlFrame}>
              <span>{bundleUrl || 'Generating url...'}</span>
              {bundleUrl &&
                <Button
                  className={customStyles.copyButton}
                  onClick={() => {
                    copyTextToClipboard(bundleUrl);
                    hidePopover();
                  }}
                  variant="primary"
                >
                  Copy link
                </Button>
              }
            </div>
          </div>
          {bundleUpdatedAt &&
            <div className={customStyles.refreshBundleWrapper}>
              <b>Public link last updated {getFormattedDurationFromNow(Number(bundleUpdatedAt))}.</b> Any
              modifications to the bundle after this point are not included.
              <Button className={customStyles.refreshBundle} onClick={refreshBundle} variant="unstyled">
                Refresh public link
              </Button>
            </div>
          }
        </div>
        <div className={customStyles.footer}>
          <Button className={customStyles.bundleButton} onClick={onClickDisable} variant="default">
            Disable public link
          </Button>
          <Button
            className={customStyles.bundleButton}
            to={bundleUrl}
            isExternal
            disabled={!bundleUrl}
            variant="blue"
          >
            View public link
          </Button>
        </div>
      </Popover>
      {confirmDisableDialog}
    </>
  );
}

import React from 'react';

import Icon from './Icon';

export default function FontSizeIcon({ className = '' }: { className?: string; }): JSX.Element {
  return (
    <Icon text="FontSizeIcon">
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.02 5.2463L16.0022 5.24609L15.9844 5.2463H11C10.5858 5.2463 10.25 5.58209 10.25 5.9963C10.25 6.41051 10.5858 6.7463 11 6.7463H15.2522V18.0011C15.2522 18.4153 15.588 18.7511 16.0022 18.7511C16.4164 18.7511 16.7522 18.4153 16.7522 18.0011V6.7463H21.0042C21.4184 6.7463 21.7542 6.41051 21.7542 5.9963C21.7542 5.58209 21.4184 5.2463 21.0042 5.2463H16.02Z"
          fill="black"
        />
        <path
          d="M6.177 10.7483V18.0014C6.177 18.4156 6.51279 18.7514 6.927 18.7514C7.34122 18.7514 7.677 18.4156 7.677 18.0014V10.7483H10.499C10.9132 10.7483 11.249 10.4125 11.249 9.99825C11.249 9.58404 10.9132 9.24825 10.499 9.24825H6.94476C6.93885 9.24812 6.93294 9.24805 6.927 9.24805C6.92107 9.24805 6.91515 9.24812 6.90925 9.24825H3.49609C3.08188 9.24825 2.74609 9.58404 2.74609 9.99825C2.74609 10.4125 3.08188 10.7483 3.49609 10.7483H6.177Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
}

import { useMemo } from 'react';

import { Token } from '../filters-compiler/types';
import {
  getFeedIdFromTokens,
  isFilteringBySingleFeedOrByFeedTrueAndSingleFeed,
} from '../utils/filteredViews';

export const useIsFilteringBySingleFeedOrByFeedTrueAndSingleFeed = ({
  filterTokens,
}: { filterTokens?: Token[]; }) => {
  return useMemo(
    () => isFilteringBySingleFeedOrByFeedTrueAndSingleFeed({ filterTokens }),
    [filterTokens],
  );
};

export const useFeedIdFromTokens = (filterTokens: Token[] | undefined) => {
  return useMemo(() => getFeedIdFromTokens(filterTokens), [filterTokens]);
};

import { useCallback } from 'react';

import type { FirstClassDocument } from '../../types';
import { globalState } from '../models';

export default function useWordBoundaryOffset(documentId?: FirstClassDocument['id']) {
  return globalState(
    useCallback(
      (state) => {
        if (!documentId) {
          return 0;
        }
        if (!state.client.documents[documentId]) {
          return 0;
        }
        return state.client.documents[documentId]?.ttsSettings?.wordTrackingOffset ?? 0;
      },
      [documentId],
    ),
  );
}

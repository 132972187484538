import React, { useCallback, useState } from 'react';
import type { MangoQuery } from 'rxdb';
import { useFindOne } from 'shared/foreground/databaseHooks';
import { markDocumentsAsOpenOrUnopen } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/documents/bulk';
import { type AnyDocument } from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';

import { useKeyboardShortcutPreventDefault } from '../hooks/useKeyboardShortcut';
import { useShortcutsMap } from '../utils/shortcuts';
import Button from './Button';
import { FloatingPill } from './FloatingPill';
import SolidCheckIcon from './icons/SolidCheckIcon';
import { MarkAllFeedDocumentsAsSeenDialog } from './MarkAllFeedDocumentsAsSeenDialog';
import styles from './MarkAllFeedSeenButton.module.css';
import Tooltip from './Tooltip';

export const MarkAllSeenButton = ({ mangoQuery }: { mangoQuery: MangoQuery<AnyDocument>; }) => {
  const [showDialog, setShowDialog] = useState(false);
  const shortcutsMap = useShortcutsMap();

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.MarkAllFeedAsSeen],
    useCallback(() => setShowDialog(true), []),
  );

  const onConfirm = () => {
    setShowDialog(false);
    markDocumentsAsOpenOrUnopen({
      docQuery: mangoQuery,
      markAsOpen: true,
      options: { userInteraction: 'click' },
    });
  };

  const [firstDoc, { isFetching }] = useFindOne('documents', mangoQuery);

  // zero documents satisfy the query
  if (!firstDoc || isFetching) {
    return null;
  }

  return (
    <>
      <MarkAllFeedDocumentsAsSeenDialog
        isOpen={showDialog}
        onCancel={() => setShowDialog(false)}
        onConfirm={onConfirm}
      />
      <FloatingPill position="left" className={styles.markAllFeedSeen}>
        <Tooltip content="Mark all as seen" shortcut={shortcutsMap[ShortcutId.MarkAllFeedAsSeen]}>
          <Button onClick={() => setShowDialog(true)}>
            <SolidCheckIcon />
            Mark all as seen
          </Button>
        </Tooltip>
      </FloatingPill>
    </>
  );
};

import React from 'react';

import Icon from './Icon';

export default function SortIcon({
  className = '',
  text = 'Sort',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10 4.25C10.4142 4.25 10.75 4.58579 10.75 5V15C10.75 15.4142 10.4142 15.75 10 15.75C9.58581 15.75 9.25002 15.4142 9.25002 15V5C9.25002 4.58579 9.58581 4.25 10 4.25Z" />
        <path d="M9.52763 3.88426C9.80313 3.66085 10.1975 3.66099 10.4728 3.88459C11.7785 4.94489 12.4701 5.63924 13.5797 6.99092C13.8425 7.31108 13.7961 7.78367 13.4759 8.04649C13.1557 8.30931 12.6831 8.26283 12.4203 7.94267C11.515 6.83984 10.9288 6.22539 9.99972 5.43987C9.07113 6.22415 8.48508 6.83857 7.57997 7.94236C7.31733 8.26265 6.84476 8.30939 6.52446 8.04675C6.20416 7.7841 6.15743 7.31153 6.42007 6.99124C7.5298 5.63791 8.22163 4.94331 9.52763 3.88426Z" />
        <path d="M6.52485 11.9139C6.84532 11.6514 7.31785 11.6985 7.58029 12.019C8.48391 13.1224 9.07074 13.7368 9.99999 14.5213C10.9288 13.7369 11.5149 13.1225 12.4201 12.0186C12.6827 11.6983 13.1553 11.6515 13.4756 11.9142C13.7959 12.1768 13.8426 12.6494 13.58 12.9697C12.4702 14.323 11.7784 15.0176 10.4724 16.0767C10.1971 16.3 9.80298 16.3 9.52763 16.0767C8.2219 15.0178 7.52846 14.3232 6.41976 12.9693C6.15732 12.6488 6.20437 12.1763 6.52485 11.9139Z" />
      </svg>
    </Icon>
  );
}

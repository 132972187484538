import type { IDrmKey } from './drmKey';

export class DrmKey implements IDrmKey {
  private _key?: CryptoKey;

  async initialize(
    salt: ArrayBuffer,
    passwordPrefix: string,
    password: string,
    iterations: number,
    keyLength: number,
  ): Promise<IDrmKey> {
    const combinedPwd = passwordPrefix + password;
    const passwordData = new TextEncoder().encode(combinedPwd);

    const importedKey = await window.crypto.subtle.importKey(
      'raw',
      passwordData,
      { name: 'PBKDF2' },
      false,
      ['deriveBits', 'deriveKey'],
    );

    this._key = await window.crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt,
        iterations,
        hash: 'SHA-1',
      },
      importedKey,
      { name: 'AES-CBC', length: keyLength * 8 },
      true,
      ['decrypt'],
    );
    return this;
  }

  async decrypt(payload: ArrayBuffer, initializationVector: ArrayBuffer): Promise<ArrayBuffer> {
    const decrypted = await window.crypto.subtle.decrypt(
      { name: 'AES-CBC', iv: initializationVector },
      this.key,
      payload,
    );
    return this.unpadPKCS7(decrypted);
  }

  async calculateHmac(data: ArrayBuffer): Promise<ArrayBuffer> {
    const hmacKey = await window.crypto.subtle.importKey(
      'raw',
      await window.crypto.subtle.exportKey('raw', this.key),
      { name: 'HMAC', hash: 'SHA-256' },
      true,
      ['sign'],
    );

    return window.crypto.subtle.sign('HMAC', hmacKey, data);
  }

  get key(): CryptoKey {
    if (!this._key) {
      throw new Error('DRM key uninitialized');
    }
    return this._key;
  }

  private unpadPKCS7(data: ArrayBuffer): ArrayBuffer {
    const view = new Uint8Array(data);
    const padLength = view[view.length - 1];
    return data.slice(0, data.byteLength - padLength);
  }
}

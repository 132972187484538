import { StateUpdateOptionsWithoutEventName } from '../../../types';
import { updateDocument } from './update';

export const toggleEpubOriginalStyles = async (
  documentId: string,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateDocument(
    documentId,
    (doc) => {
      if (!doc?.source_specific_data?.epub?.originalStylesEnabled) {
        doc.source_specific_data = {
          ...doc?.source_specific_data ?? {},
          epub: {
            ...doc?.source_specific_data?.epub ?? {},
            originalStylesEnabled: true,
          },
        };
      } else {
        doc.source_specific_data.epub.originalStylesEnabled = false;
      }
    },
    { ...options, eventName: 'epub-original-styles-toggled' },
  );
};

export const setAreEpubOriginalStylesEnabled = async (
  documentId: string,
  value: boolean,
  options: StateUpdateOptionsWithoutEventName,
) => {
  await updateDocument(
    documentId,
    (doc) => {
      if (!doc?.source_specific_data?.epub?.originalStylesEnabled) {
        doc.source_specific_data = {
          ...doc?.source_specific_data ?? {},
          epub: {
            ...doc?.source_specific_data?.epub ?? {},
            originalStylesEnabled: value,
          },
        };
      } else {
        doc.source_specific_data.epub.originalStylesEnabled = value;
      }
    },
    { ...options, eventName: 'epub-original-styles-set' },
  );
};

import type { CheckboxProps } from '@radix-ui/react-checkbox';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import React, { useMemo } from 'react';

import styles from './Checkbox.module.css';
import CheckIcon from './icons/CheckIcon';
import IndeterminateIcon from './icons/IndeterminateIcon';
import MinusIcon from './icons/MinusCheckboxIcon';

type Props = {
  isChecked: boolean | 'indeterminate';
  labelId: string;
  ariaLabel?: string;
  isMinusIcon?: boolean;
} & Omit<CheckboxProps, 'checked'>;

export default React.memo(function Checkbox({
  className,
  isChecked,
  labelId,
  onCheckedChange,
  ariaLabel,
  isMinusIcon = false,
}: Props) {
  const classes = useMemo(() => {
    return ['checkbox', styles.checkbox, className, isChecked ? styles.checkboxChecked : null]
      .filter(Boolean)
      .join(' ');
  }, [isChecked, className]);

  return (
    <RadixCheckbox.Root
      aria-labelledby={labelId}
      aria-label={ariaLabel}
      id={labelId}
      name={labelId}
      className={classes}
      checked={isChecked}
      onCheckedChange={onCheckedChange}
    >
      <RadixCheckbox.Indicator>
        {isChecked === 'indeterminate' && <IndeterminateIcon />}
        {isChecked === true &&
          (isMinusIcon ? <MinusIcon className={styles.isMinusIcon} /> : <CheckIcon />)}
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
  );
});

// Creating a custom checkbox because for some fucking reason when using Radix in
// a list with 800 items makes everything slower :( We debugged it for a while with Adam
// and we couldn't see antyhing wrong.
export const CustomCheckbox = ({
  isChecked,
  onCheckedChange,
  label,
}: { isChecked: boolean; onCheckedChange: () => void; label: string; }) => {
  return (
    <label className={styles.checkboxContainer}>
      <input
        type="checkbox"
        aria-label={label}
        checked={isChecked}
        onClick={onCheckedChange}
        onChange={() => null}
      />
      <span className={styles.checkmark}>
        <CheckIcon />
      </span>
    </label>
  );
};

import React from 'react';

import Icon from './Icon';

export default function TrashIcon84Stroke({
  className = '',
  text = 'Trash',
}: {
  className?: string;
  text?: string;
}): JSX.Element {
  return (
    <Icon text={text}>
      <svg width="84" height="84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.8 25.2h50.4M21 25.2l3.822 38.218A4.2 4.2 0 0 0 29 67.2h25.998a4.2 4.2 0 0 0 4.18-3.782L63 25.2m-27.3 28v-14m12.6 14v-14M33.6 16.8v-4.2h16.8v4.2"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}

import React, { Suspense, useState } from 'react';
import database from 'shared/foreground/database';
import { createToast } from 'shared/foreground/toasts.platform';
import useFilteredViewMangoQuery from 'shared/foreground/useFilteredViewMangoQuery';
import { type FilteredView, SortKey, SortOrder } from 'shared/types';
import exceptionHandler from 'shared/utils/exceptionHandler.platform';

import useDocumentIdsForDocumentList from '../hooks/useDocumentIdsForDocumentList';
import Button from './Button';
import DocumentListLoaderSkeleton from './DocumentListLoaderSkeleton';
import Documents from './Documents';
import { EmptyTrashDialog } from './EmptyTrashDialog';
import { FloatingPill } from './FloatingPill';
import TrashIcon84Stroke from './icons/84StrokeTrashIcon';
import TrashIcon from './icons/TrashIcon';
import styles from './TrashPage.module.css';

const savedView: FilteredView = {
  id: 'trash',
  query: 'trash:true',
  name: 'Trash',
  icon: 'trash',
  sortRules: [
    {
      id: 'trash-sort-key-updated-at',
      key: SortKey.UpdatedAt,
      order: SortOrder.Desc,
    },
  ],
};

const EmptyTrash = () => {
  return (
    <div className={styles.emptyTrash}>
      <TrashIcon84Stroke />
      <div className={styles.emptyTrashContent}>
        <h2>Trash</h2>
        <p>You have no documents in your trash.</p>
      </div>
    </div>
  );
};

export const TrashPage: React.FC = () => {
  const [isEmptyTrashDialogOpen, setIsEmptyTrashDialogOpen] = useState(false);
  const { createMangoQuery, currentSortRule } = useFilteredViewMangoQuery({
    view: savedView,
    query: '_deleted:true',
  });

  const {
    documentIds,
    fetchMore,
    pageSize,
    totalCount,
    shouldShowSkeleton: shouldShowSkeletonFromDocumentIds,
  } = useDocumentIdsForDocumentList({
    createMangoQuery,
  });

  const handleEmptyTrash = async () => {
    try {
      // Clears deleted documents manually with the cleanup plugin.
      await database.collections.documents.clearDeleted();

      // RxDB only updates the document list when the page is reloaded.
      // TODO: Investigate whether cleanup function emits an event that can be listened to or create one.
      window.location.reload();
    } catch (error) {
      setIsEmptyTrashDialogOpen(false);
      createToast({
        category: 'error',
        content: 'Failed to empty trash',
      });

      exceptionHandler.captureException(error);
    }
  };

  const isEmpty = totalCount === 0;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <TrashIcon />
          <h1>Trash</h1>
        </div>
      </div>
      <div className={styles.content}>
        <Suspense fallback={null}>
          {isEmpty
            ? <EmptyTrash />
           : shouldShowSkeletonFromDocumentIds
            ? <DocumentListLoaderSkeleton />
           : <>
              <Documents
                documentPathPrefix="trash"
                key="trash"
                listedDocumentIds={documentIds}
                openDocumentId={undefined}
                parentPath="trash"
                currentSortRule={currentSortRule}
                onEndThresholdReached={fetchMore}
                pageSize={pageSize}
                isTrashPage
              />
              <FloatingPill position="left" className={styles.emptyTrashButton}>
                <Button onClick={() => setIsEmptyTrashDialogOpen(true)} variant="danger">
                  <TrashIcon />
                  <span>Empty Trash</span>
                </Button>
              </FloatingPill>
              <FloatingPill isRightSidebarInScreen={false}>
                <>Count: {totalCount}</>
              </FloatingPill>
              <EmptyTrashDialog
                trashCount={totalCount ?? 0}
                isOpen={isEmptyTrashDialogOpen}
                onConfirm={handleEmptyTrash}
                onCancel={() => {
                  setIsEmptyTrashDialogOpen(false);
                }}
              />
            </>
          }
        </Suspense>
      </div>
    </div>
  );
};

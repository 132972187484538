import React from 'react';

import Icon from './Icon';

export default function CreateHighlightIcon({ className }: { className?: string; }): JSX.Element {
  return (
    <Icon text="Create highlight">
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.30611 15.1523L4.45236 17.2986L3.58154 18.1694C3.45755 18.2934 3.27416 18.3367 3.10781 18.2812L0.87045 17.5355C0.543979 17.4266 0.446164 17.0123 0.689502 16.769L2.30611 15.1523Z"
          fill="currentColor"
        />
        <path
          d="M6.98807 16.835C6.69226 16.9829 6.33502 16.9249 6.10117 16.6911L2.95405 13.544C2.7202 13.3101 2.66222 12.9529 2.81013 12.6571L3.5425 11.1923C3.80778 10.6618 3.98402 10.0912 4.06419 9.50347C4.26023 8.06616 5.05348 6.77823 6.24885 5.95641L13.4553 1.00195L13.7394 1.0554C13.7714 1.06191 13.8032 1.0688 13.835 1.07654C13.8864 1.08907 13.9565 1.10769 14.0428 1.1342C14.2154 1.18719 14.4532 1.27188 14.7351 1.40269C15.2993 1.66451 16.0408 2.11147 16.7872 2.85793C17.5337 3.60438 17.9806 4.34585 18.2425 4.91005C18.3733 5.19194 18.458 5.42974 18.511 5.60231C18.5375 5.68863 18.5561 5.7588 18.5686 5.81019C18.5749 5.8359 18.5796 5.85693 18.5831 5.87296C18.5983 5.94737 18.6121 6.02208 18.6259 6.09678C18.6316 6.12777 18.6374 6.15885 18.6432 6.18982L13.6888 13.3962C12.867 14.5916 11.579 15.3849 10.1416 15.5811C9.554 15.6612 8.98357 15.8375 8.45311 16.1026L6.98807 16.835ZM17.1494 5.90925C17.1118 5.79933 17.0576 5.65865 16.9817 5.49511C16.7786 5.05735 16.42 4.45634 15.8044 3.84073C15.1888 3.22511 14.5878 2.86659 14.15 2.66344C13.9865 2.58755 13.8458 2.53339 13.7359 2.49573L7.03719 7.10109C7.02461 7.10932 7.0121 7.11771 6.99966 7.12626L6.59939 7.40122C5.58109 8.10074 5.4486 9.55328 6.32355 10.4255L9.21946 13.3123C10.0897 14.1798 11.5327 14.0497 12.2336 13.0404L12.3835 12.8246C12.4392 12.7548 12.4926 12.6829 12.5435 12.6088L17.1494 5.90925Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}

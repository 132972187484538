import React from 'react';

import Icon from './Icon';

export default function DeleteHighlightIcon({ className }: { className?: string; }): JSX.Element {
  return (
    <Icon text="Delete highlight">
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5303 4.46967C15.8232 4.76256 15.8232 5.23744 15.5303 5.53033L5.53033 15.5303C5.23744 15.8232 4.76256 15.8232 4.46967 15.5303C4.17678 15.2374 4.17678 14.7626 4.46967 14.4697L14.4697 4.46967C14.7626 4.17678 15.2374 4.17678 15.5303 4.46967Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.46967 4.46967C4.17678 4.76256 4.17678 5.23744 4.46967 5.53033L14.4697 15.5303C14.7626 15.8232 15.2374 15.8232 15.5303 15.5303C15.8232 15.2374 15.8232 14.7626 15.5303 14.4697L5.53033 4.46967C5.23744 4.17678 4.76256 4.17678 4.46967 4.46967Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
}

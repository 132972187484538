import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import { useEffect, useMemo } from 'react';

import { convertQueryToRxDBQuery } from '../../filters-compiler/convertQueryToRxDBQuery';
import type { Highlight, Note, PartialDocument } from '../../types';
import { FilteredView, FirstClassDocument } from '../../types';
import type { DatabaseHookResultArray } from '../../types/database';
import { isDocumentThatCanHaveHighlights, notEmpty } from '../../typeValidators';
import makeLogger from '../../utils/makeLogger';
import { useCount, useFindByIds, useFindOne } from '../databaseHooks';
import getSplitByDefaultValue from '../utils/getSplitByDefaultValue';
import useDocumentLocations from '../utils/useDocumentLocations';

const logger = makeLogger(__filename);

export const useHighlights = ({
  excludePDFHighlights = false,
  isEnabled = true,
  parentDocId,
}: {
  excludePDFHighlights?: boolean;
  isEnabled?: boolean;
  parentDocId?: string | null;
}): Highlight[] => {
  const [doc] = useFindOne('documents', parentDocId ?? undefined, { isEnabled });
  const children = useMemo(() => {
    if (!doc) {
      return [];
    }
    if (!isDocumentThatCanHaveHighlights(doc)) {
      return [];
    }
    return doc.children;
  }, [doc]);
  const [allHighlights, { isFetching }] = useFindByIds<'documents', Highlight>('documents', children, {
    isEnabled,
  });

  useEffect(() => {
    if (isFetching) {
      return;
    }
    for (const highlightId of children) {
      const highlightDoc = allHighlights.find(({ id }) => id === highlightId);
      if (!highlightDoc) {
        logger.error('Highlight does not exist', { highlightId });
      }
    }
  }, [allHighlights, children, isFetching]);
  return useMemo(() => {
    const highlights = excludePDFHighlights
      ? allHighlights.filter((h) => !h.source_specific_data?.pdf_highlight)
      : allHighlights;
    // This orderby guarantees that the highlights will be in the same order for memo purposes
    return orderBy(highlights, ['offset']);
  }, [allHighlights, excludePDFHighlights]);
};

export const useHighlightsMap = (parentDocId?: string): { [highlightId: string]: Highlight; } => {
  const highlights = useHighlights({ parentDocId });
  return useMemo(() => keyBy(highlights, 'id'), [highlights]);
};

export function useHighlightNote(
  highlight: PartialDocument<Highlight, 'children'> | null | void,
): DatabaseHookResultArray<Note | null> {
  const noteDocumentId = highlight?.children.length ? highlight.children[0] : undefined;
  return useFindOne('documents', noteDocumentId);
}

export function useHighlightNoteTextsMap(highlights: PartialDocument<Highlight, 'children'>[]): {
  [highlightId: string]: string;
} {
  const noteIds = useMemo(
    () => highlights.map((highlight) => highlight.children[0]).filter(notEmpty),
    [highlights],
  );
  const [notes] = useFindByIds('documents', noteIds);
  return useMemo(() => mapValues(keyBy(notes, 'parent'), ({ content }) => content ?? ''), [notes]);
}

export function useHighlightNoteText(highlight: Parameters<typeof useHighlightNote>[0]): string {
  const [noteDocument] = useHighlightNote(highlight);
  return useMemo(() => noteDocument?.content ?? '', [noteDocument]);
}

export const useViewBadgeContent = ({
  view,
  shouldRun: shouldRunParam = true,
}: { view: FilteredView; shouldRun?: boolean; }) => {
  const documentLocations = useDocumentLocations();
  const isEnabled = Boolean(view.showCountBadge);
  const shouldRun = shouldRunParam && isEnabled;
  const splitByValue =
    shouldRun && view.splitBy
      ? getSplitByDefaultValue(view.splitBy, documentLocations) ?? undefined
      : undefined;

  const { mangoQuery } = useMemo(
    () =>
      shouldRun
        ? convertQueryToRxDBQuery<FirstClassDocument>({
          query: view.query,
          splitBy: view.splitBy,
          splitByValue,
        })
        : {
          mangoQuery: undefined,
        },
    [splitByValue, view.query, view.splitBy, shouldRun],
  );

  const [docsCount] = useCount('documents', mangoQuery ?? {}, {
    isEnabled: Boolean(shouldRun && mangoQuery),
  });

  if (!shouldRun) {
    return undefined;
  }

  if (docsCount === 0) {
    return undefined;
  }

  if (docsCount > 1000) {
    return '1K+';
  }

  return `${docsCount}`;
};

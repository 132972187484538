import { type FirstClassDocument } from '../../types';
import database from '../database';
import { createToast } from '../toasts.platform';

export const recoverDocumentEventName = 'document-recovered';

export class DocumentDoesNotExistError extends Error {
  name = 'DocumentDoesNotExistError';
}

export const recoverDocument = async (
  documentId: string,
  onNavigate: (doc: FirstClassDocument) => void,
) => {
  const doc = await database.collections.documents.findOne({
    selector: {
      id: documentId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      _deleted: true,
    },
  });

  if (!doc) {
    throw new DocumentDoesNotExistError("Document doesn't exist in database");
  }

  const highlightDocIds = doc.children ?? [];

  const allIds = [documentId, ...highlightDocIds];

  try {
    // Recover the document and all associated highlights
    const updateResult = await database.collections.documents.findAndIncrementalModify(
      {
        selector: {
          id: {
            $in: allIds,
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          _deleted: true,
        },
      },
      (doc) => {
        if (!doc) {
          return doc;
        }
        return {
          ...doc,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          _deleted: false,
        };
      },
      {
        eventName: recoverDocumentEventName,
        userInteraction: 'click',
      },
    );

    // Recover all associated notes from document and highlights

    const noteIds = updateResult.queryResult.flatMap((doc) => doc.children);

    if (noteIds.length > 0) {
      // This will restore all associated notes
      await database.collections.documents.findAndIncrementalModify(
        {
          selector: {
            id: {
              $in: noteIds,
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            _deleted: true,
          },
        },
        (note) => {
          if (!note) {
            return note;
          }
          return {
            ...note,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            _deleted: false,
          };
        },
        {
          eventName: recoverDocumentEventName,
          userInteraction: 'click',
        },
      );
    }

    // Recover all associated tags from document and highlights if they were deleted

    const tagIds = updateResult.queryResult.flatMap((doc) => Object.keys(doc.tags ?? {}));

    if (tagIds.length > 0) {
      await database.collections.global_tags.findAndIncrementalModify(
        {
          selector: {
            id: {
              $in: tagIds,
            },
            // @ts-expect-error TODO: fix this
            // eslint-disable-next-line @typescript-eslint/naming-convention
            _deleted: true,
          },
        },
        (tag) => {
          return {
            ...tag,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            _deleted: false,
          };
        },
        {
          eventName: recoverDocumentEventName,
          userInteraction: 'click',
        },
      );
    }

    if (doc.category !== 'highlight' && doc.category !== 'note') {
      createToast({
        content: `Document restored`,
        category: 'success',
        buttonText: 'Open',
        onButtonClick: () => {
          onNavigate(doc as FirstClassDocument);
        },
      });
    }

    return updateResult;
  } catch (error) {
    createToast({
      content: `Failed to restore document`,
      category: 'error',
    });
  }
};

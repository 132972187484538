import { useCallback, useEffect, useMemo, useState } from 'react';

import type { FirstClassDocument, ReadingPosition } from '../../types';
import makeLogger from '../../utils/makeLogger';
import {
  updateReadingPosition,
  updateScrollPosition,
} from '../stateUpdaters/persistentStateUpdaters/documents/progressRelated';

export type UpdateReadingProgress = (
  pos: ReadingPosition,
  oldReadingPositionScrollTop: number,
  scrollY: number,
) => void;

type TrackWords = {
  track: () => number;
  updateReadingProgress: UpdateReadingProgress;
};

const logger = makeLogger(__filename, { shouldLog: false });

const getWordsPerMinuteCalculator = (initialScrollY: number) => {
  let lastScrollPosArray = new Array(50).fill(initialScrollY);
  let totalWordsRead = 0;
  let wordsPerMinute = 0;

  const calculate = (
    wordCount: number,
    windowHeight: number,
    contentHeight: number,
    currentScrollOffsetY: number,
    wpmThreshold: number,
    updateSkimmingTimer: (val: number) => void,
  ) => {
    const wordsOnScreen = wordCount > 0 ? windowHeight / contentHeight * wordCount : 0;
    lastScrollPosArray.push(currentScrollOffsetY);

    // A new scroll value has been calculated
    // Figure out the amount of words we have seen because of this scroll
    // If its zero, set the number of words to -1 (at a rate of 200WPM, a person reads about 1 word per 300MS) (200words/60 = 3 words per second / 3 = 1 word per 300MS)

    const getNumberOfVisibleWords = (lastPos: number, curPos: number) => {
      const delta = Math.abs(lastPos - curPos);
      return delta / windowHeight * wordsOnScreen;
    };

    let arrayLength = lastScrollPosArray.length;
    let wordsInLastInterval = getNumberOfVisibleWords(
      lastScrollPosArray[arrayLength - 2],
      lastScrollPosArray[arrayLength - 1],
    );
    if (wordsInLastInterval === 0) {
      wordsInLastInterval = -1;
    }

    // Add that word amount to the total words count
    // Total words are ALL the words we have seen in the last 30 seconds, -3 per second while staying stationary
    totalWordsRead += wordsInLastInterval;

    // Each time we log a new scroll position, remove the scroll position from 30 seconds ago
    // Subtract this total from the totalWords seen in the last 30 seconds
    if (arrayLength > 100) {
      totalWordsRead -= getNumberOfVisibleWords(lastScrollPosArray[0], lastScrollPosArray[1]);
      lastScrollPosArray = lastScrollPosArray.slice(1);
      arrayLength -= 1;
    }

    // Simulate staying in one place until threshold is reached to
    // calculate and update the skimming timer.
    let arrayCopy = Array.from(lastScrollPosArray);
    let loopsUntilReading = 0;
    let simTotalWordsRead = totalWordsRead;
    while (simTotalWordsRead / (arrayCopy.length * 0.3) * 30 > wpmThreshold) {
      loopsUntilReading++;
      arrayCopy.push(currentScrollOffsetY);
      const length = arrayCopy.length;
      if (length > 100) {
        simTotalWordsRead -= getNumberOfVisibleWords(arrayCopy[0], arrayCopy[1]);
        arrayCopy = arrayCopy.slice(1);
      }
      const w = getNumberOfVisibleWords(arrayCopy[length - 1], arrayCopy[length - 2]);
      simTotalWordsRead += w ? w : -1;
    }
    updateSkimmingTimer(loopsUntilReading * 0.3);

    // Make sure we never go negative, otherwise things are weird
    if (totalWordsRead < 0) {
      totalWordsRead = 0;
    }
    // Since totalWords is the amount of words we saw in the last 30 seconds, we can compute the
    // Words per minute at this specific point in time
    wordsPerMinute = totalWordsRead / (arrayLength * 0.3) * 30;
    return wordsPerMinute;
  };

  return calculate;
};

let updateReadingPositionTimer: number;
let updateScrollPositionTimer: number;
export const trackWordsPerMinute = ({
  docId,
  contentHeight,
  windowHeight,
  wordCount,
  initialScrollPosition,
  maybeTransformPosition,
  wpmThreshold,
  isReadingProgressTrackingEnabled,
  updateSkimmingTimer,
  setIsUserReading,
}: {
  docId: FirstClassDocument['id'] | undefined;
  contentHeight: number;
  windowHeight: number;
  wordCount: number;
  initialScrollPosition: number;
  maybeTransformPosition: (position: ReadingPosition) => Promise<ReadingPosition | undefined>;
  wpmThreshold: number;
  isReadingProgressTrackingEnabled: boolean;
  updateSkimmingTimer: (val: number) => void;
  setIsUserReading: (val: boolean) => void;
}): TrackWords => {
  logger.info(`Initializing reading progress tracking, isEnabled: ${isReadingProgressTrackingEnabled}`);
  let isReading = Boolean(isReadingProgressTrackingEnabled);
  setIsUserReading(isReading);

  let wordsPerMinute = 0;
  let _currentScrollOffsetY = initialScrollPosition;
  let _internalReadingPosition: ReadingPosition;

  // Use this ID to track latest async call for either update function
  let currentUpdateScrollPositionCallbackId = 0;
  let currentUpdateReadingPositionCallbackId = 0;

  const updateInternalScrollOffsetY = (newOffsetY: number) => {
    _currentScrollOffsetY = newOffsetY;
  };
  const updateInternalReadingPosition = (newPos: ReadingPosition) => {
    _internalReadingPosition = newPos;
  };

  const wpmCalculator = getWordsPerMinuteCalculator(initialScrollPosition);

  const track = () => {
    // This function tracks the seen WPM and decides if we are in skimming or reading mode
    // If we pass a certain threshold of WPM in a specific time, we trigger skimming
    // if that value reduces to a "reading" threshold, we switch to reading
    return setInterval(() => {
      wordsPerMinute = wpmCalculator(
        wordCount,
        windowHeight,
        contentHeight,
        _currentScrollOffsetY,
        wpmThreshold,
        updateSkimmingTimer,
      );
      if (isReadingProgressTrackingEnabled) {
        // logger.info(`wpm: ${wordsPerMinute}`);
        if (wordsPerMinute > wpmThreshold) {
          isReading = false;
          setIsUserReading(false);
        } else if (!isReading) {
          // Triggered when we switch from skimming back to reading
          isReading = true;
          setIsUserReading(true);
          currentUpdateReadingPositionCallbackId += 1;
          currentUpdateScrollPositionCallbackId += 1;
          updateReadingPositionCallback(_internalReadingPosition);
        }
      }
    }, 300) as unknown as number;
  };

  const updateScrollPositionCallback = async (newPosition: ReadingPosition) => {
    if (!docId) {
      return;
    }
    const currentId = currentUpdateScrollPositionCallbackId;
    // This function allows the user of the useReadingProgressTracking hook to change/add data to the scroll position
    // right before a DB write
    const readingPosition = await maybeTransformPosition(newPosition);
    // Check for outdated calls caused by slow getReadingPosition execution
    if (currentId !== currentUpdateScrollPositionCallbackId || !readingPosition) {
      return;
    }

    await updateScrollPosition(docId, readingPosition, {
      errorMessageIfNothingFound: false,
      eventName: 'document-scroll-position-updated',
      userInteraction: 'scroll',
      isUndoable: false,
    });
  };
  const updateReadingPositionCallback = async (newPosition: ReadingPosition) => {
    if (!docId) {
      return;
    }
    const currentId = currentUpdateReadingPositionCallbackId;
    // This function allows the user of the useReadingProgressTracking hook to change/add data to the scroll position
    // right before a DB write
    const readingPosition = await maybeTransformPosition(newPosition);
    // Check for outdated calls caused by slow getReadingPosition execution
    if (currentId !== currentUpdateReadingPositionCallbackId || !readingPosition) {
      return;
    }

    if (isReading && isReadingProgressTrackingEnabled) {
      logger.info(`Updating reading position to pos ${readingPosition.serializedPosition}`);
      await updateReadingPosition(docId, readingPosition, {
        force: true,
        errorMessageIfNothingFound: false,
        eventName: 'document-progress-position-updated',
        isUndoable: false,
        userInteraction: 'scroll',
      });
    }
  };

  /*
    This is the meat of this hook
    This function is returned from the useReadingProgressTracking hook and is called whenever the
    caller wants to update reading progress (like a scroll event just ended)

    Within here, we update the scroll position first, and then the reading position.
    the reading position is delayed a bit to make sure we truly are reading and not skimming

    The updatePosition callbacks both utilize a function called getReadingPosition, where they
   */
  const updateReadingProgress = (
    newReadingPosition: ReadingPosition,
    oldReadingPositionScrollTop: number,
    scrollY: number,
  ) => {
    updateInternalScrollOffsetY(scrollY);
    updateInternalReadingPosition(newReadingPosition);

    if (updateReadingPositionTimer) {
      clearTimeout(updateReadingPositionTimer);
    }
    if (updateScrollPositionTimer) {
      clearTimeout(updateScrollPositionTimer);
    }
    currentUpdateReadingPositionCallbackId += 1;
    currentUpdateScrollPositionCallbackId += 1;
    if (newReadingPosition) {
      // Scroll position can be updated much faster but due to server load, we throttle this for now
      updateScrollPositionTimer = setTimeout(
        () => updateScrollPositionCallback(newReadingPosition),
        2000,
      ) as unknown as number;
      // Set the timeout to 2000ms to allow a bit of buffer for user
      // to enter skimming mode without accidentally updating reading progress too early
      if (oldReadingPositionScrollTop < scrollY) {
        updateReadingPositionTimer = setTimeout(
          () => updateReadingPositionCallback(newReadingPosition),
          2000,
        ) as unknown as number;
      }
    }
  };

  return {
    track,
    updateReadingProgress,
  };
};

export const useReadingProgressTracking = ({
  docId,
  contentHeight,
  windowHeight,
  wordCount,
  startingScrollPosition,
  transformReadingPosition,
  wpmThreshold,
  isEnabled = true,
  updateSkimmingTimer,
}: {
  docId: FirstClassDocument['id'] | undefined;
  contentHeight: number;
  windowHeight: number;
  wordCount: number;
  startingScrollPosition: { value: number; };
  transformReadingPosition?: (readingPosition: ReadingPosition) => Promise<ReadingPosition | undefined>;
  wpmThreshold: number;
  isEnabled?: boolean;
  updateSkimmingTimer: (val: number) => void;
}) => {
  // Keep track of the latest updateProgress function in this ref
  const defaultTransformPositionFunction = useCallback(
    (position: ReadingPosition) => Promise.resolve(position),
    [],
  );
  const [isUserReading, setIsUserReading] = useState(true);

  useEffect(() => {
    logger.info(`Is user reading: ${isUserReading}`);
  }, [isUserReading]);

  const { track, updateReadingProgress } = useMemo(
    () =>
      trackWordsPerMinute({
        docId,
        contentHeight,
        windowHeight,
        wordCount: wordCount || 0,
        initialScrollPosition: startingScrollPosition.value,
        maybeTransformPosition: transformReadingPosition ?? defaultTransformPositionFunction,
        wpmThreshold,
        isReadingProgressTrackingEnabled: isEnabled,
        updateSkimmingTimer,
        setIsUserReading,
      }),
    [
      contentHeight,
      defaultTransformPositionFunction,
      docId,
      startingScrollPosition,
      isEnabled,
      transformReadingPosition,
      updateSkimmingTimer,
      windowHeight,
      wordCount,
      wpmThreshold,
    ],
  );

  useEffect(() => {
    const tracker = track();
    return () => {
      clearInterval(tracker);
      if (updateScrollPositionTimer) {
        clearInterval(updateScrollPositionTimer);
      }
      if (updateReadingPositionTimer) {
        clearInterval(updateReadingPositionTimer);
      }
    };
  }, [track]);
  return { updateReadingProgress, isUserReading };
};

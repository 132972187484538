import { DrmKey } from './drmKey.platform';

class DecryptionError extends Error {}

/**
 * Decryption Module
 *
 * This code decrypts data encrypted with AES-CBC. It uses PBKDF2 for key derivation
 * and HMAC for integrity checks.
 *
 * How it works:
 * 1. Extracts salt, IV, payload, and HMAC from encrypted data
 * 2. Verifies HMAC to check data integrity
 * 3. Derives decryption key from password using PBKDF2 (with 2000 iterations)
 * 4. Decrypts payload with AES-CBC using derived key
 * 5. Removes PKCS7 padding
 *
 * Notes:
 * - Under the hood: Uses builtin Web Crypto API on web, react-native-quick-crypto on mobile.
 * - Has a hardcoded password prefix (security risk! but that's how Glose did it too)
 */
class Decryptor {
  private static readonly saltSize = 8;
  private static readonly initializationVectorSize = 16;
  private static readonly keyLength = 32;
  private static readonly iterations = 2000;
  private static readonly hmacSize = 32;

  private readonly passwordPrefix: string = 'q7on5nbhne3wpwoqslw5r6gspqmzct7l';
  private readonly password: string;

  constructor(password: string) {
    this.password = password;
  }

  async decrypt(encryptedData: ArrayBuffer): Promise<ArrayBuffer> {
    const view = new DataView(encryptedData);
    const version = view.getUint8(0);
    const option = view.getUint8(1);

    if (version !== 3 || option !== 1) {
      throw new DecryptionError('Unsupported version or option');
    }

    const salt = encryptedData.slice(2, 2 + Decryptor.saltSize);
    const hmacSalt = encryptedData.slice(10, 10 + Decryptor.saltSize);
    const initializationVector = encryptedData.slice(18, 18 + Decryptor.initializationVectorSize);
    const encryptedPayload = encryptedData.slice(34, -Decryptor.hmacSize);
    const hmac = encryptedData.slice(-Decryptor.hmacSize);

    const hmacKey = await new DrmKey().initialize(
      hmacSalt,
      this.passwordPrefix,
      this.password,
      Decryptor.iterations,
      Decryptor.keyLength,
    );

    const calculatedHmac = await hmacKey.calculateHmac(encryptedData.slice(0, -Decryptor.hmacSize));
    if (!this.compareArrayBuffers(hmac, calculatedHmac)) {
      throw new DecryptionError('HMAC verification failed');
    }

    const decryptionKey = await new DrmKey().initialize(
      salt,
      this.passwordPrefix,
      this.password,
      Decryptor.iterations,
      Decryptor.keyLength,
    );

    return decryptionKey.decrypt(encryptedPayload, initializationVector);
  }

  private compareArrayBuffers(a: ArrayBuffer, b: ArrayBuffer): boolean {
    if (a.byteLength !== b.byteLength) {
      return false;
    }
    const aView = new Uint8Array(a);
    const bView = new Uint8Array(b);
    for (let i = 0; i < a.byteLength; i++) {
      if (aView[i] !== bView[i]) {
        return false;
      }
    }
    return true;
  }
}

export async function decryptData(
  encryptedData: ArrayBufferLike,
  password: string,
): Promise<ArrayBuffer> {
  const decryptor = new Decryptor(password);
  return decryptor.decrypt(encryptedData);
}

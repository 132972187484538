import React from 'react';

import Icon from './Icon';

export default function TrashIcon({
  className = '',
  text = 'Trash',
}: {
  className?: string;
  text?: string;
}): JSX.Element {
  return (
    <Icon text={text}>
      <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
        <path
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M4 6h12M5 6l.91 9.1a1 1 0 0 0 .995.9h6.19a1 1 0 0 0 .995-.9L15 6m-6.5 6.667V9.333m3 3.334V9.333M8 4V3h4v1"
        />
      </svg>
    </Icon>
  );
}

import React from 'react';

import Icon from './Icon';

export default function LaterIcon({
  className,
  text = 'later',
}: { className?: string; text: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.48238 2.1233C9.5511 2.53178 9.27568 2.91862 8.86721 2.98734C8.42285 3.0621 7.98662 3.17904 7.56444 3.33655C7.17636 3.48135 6.74438 3.28413 6.59958 2.89605C6.45479 2.50797 6.65201 2.07599 7.04009 1.93119C7.55154 1.74036 8.08001 1.5987 8.61834 1.50813C9.02681 1.43941 9.41366 1.71483 9.48238 2.1233Z" />
        <path d="M10.6486 2.12333C10.7175 1.71487 11.1044 1.43957 11.5128 1.50841C15.8726 2.24326 18.9836 6.13731 18.7376 10.5517C18.4917 14.9661 14.9673 18.4904 10.5529 18.7364C6.13854 18.9824 2.24448 15.8714 1.50964 11.5116C1.44079 11.1032 1.71609 10.7162 2.12455 10.6474C2.533 10.5786 2.91993 10.8539 2.98877 11.2623C3.59664 14.8687 6.81785 17.4422 10.4695 17.2387C14.1211 17.0353 17.0365 14.1199 17.2399 10.4683C17.4434 6.81663 14.87 3.59542 11.2635 2.98755C10.8551 2.9187 10.5798 2.53178 10.6486 2.12333Z" />
        <path d="M2.89498 6.59977C3.28317 6.74426 3.48074 7.17609 3.33625 7.56428C3.17905 7.98661 3.06236 8.42293 2.98775 8.86735C2.91917 9.27584 2.53243 9.5514 2.12393 9.48282C1.71543 9.41424 1.43988 9.02749 1.50845 8.619C1.5988 8.08083 1.74011 7.55247 1.93047 7.04104C2.07496 6.65285 2.50678 6.45528 2.89498 6.59977Z" />
        <path d="M5.48547 4.52975C5.80483 4.26596 5.84988 3.79323 5.5861 3.47387C5.32231 3.15451 4.84958 3.10946 4.53022 3.37325C4.10852 3.72156 3.72089 4.10919 3.37258 4.53089C3.10879 4.85025 3.15384 5.32298 3.4732 5.58677C3.79256 5.85055 4.26529 5.8055 4.52908 5.48614C4.81684 5.13775 5.13708 4.81751 5.48547 4.52975Z" />
        <path d="M9.37497 5.71698C9.37497 5.30277 9.71076 4.96698 10.125 4.96698C10.5392 4.96698 10.875 5.30277 10.875 5.71698V9.3749H13.6513C14.0655 9.3749 14.4013 9.71069 14.4013 10.1249C14.4013 10.5391 14.0655 10.8749 13.6513 10.8749H10.125C9.71076 10.8749 9.37497 10.5391 9.37497 10.1249V5.71698Z" />
      </svg>
    </Icon>
  );
}

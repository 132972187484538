import React from 'react';

import Icon from './Icon';

export default function ShortlistIcon({
  className,
  text = 'Shortlist',
}: { className?: string; text: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.05035 2.32507C9.35226 1.40914 10.6479 1.40914 10.9498 2.32507L12.4311 6.81902H17.2217C18.1938 6.81902 18.5943 8.06595 17.8039 8.63202L13.9407 11.3988L15.4168 15.8772C15.7197 16.796 14.6714 17.5665 13.8848 17.0032L10.0001 14.221L6.11533 17.0032C5.32882 17.5665 4.28048 16.796 4.58333 15.8772L6.05948 11.3988L2.19626 8.63202C1.40586 8.06595 1.80632 6.81902 2.77852 6.81902H7.56906L9.05035 2.32507ZM10.0001 4.23531L8.88045 7.63207C8.74532 8.04204 8.36238 8.31902 7.93071 8.31902H4.33538L7.23166 10.3933C7.58719 10.6479 7.73603 11.104 7.59913 11.5193L6.48757 14.8916L9.41782 12.793C9.76596 12.5437 10.2342 12.5437 10.5823 12.793L13.5126 14.8916L12.401 11.5193C12.2641 11.104 12.413 10.6479 12.7685 10.3933L15.6648 8.31902H12.0695C11.6378 8.31902 11.2549 8.04204 11.1197 7.63207L10.0001 4.23531Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}

import { base64Decode, base64Encode } from './base64Impl.platform';

export function rawStringToArrayBuffer(rawBytesString: string) {
  return Uint8Array.from(rawBytesString, (c) => c.charCodeAt(0)).buffer;
}

export function arrayBufferToRawString(buffer: ArrayBufferLike): string {
  return Array.from(new Uint8Array(buffer), (c) => String.fromCharCode(c)).join('');
}

export function base64ToArrayBuffer(base64String: string): ArrayBufferLike {
  return rawStringToArrayBuffer(base64Decode(base64String));
}

export function arrayBufferToBase64(buffer: ArrayBufferLike): string {
  return base64Encode(arrayBufferToRawString(buffer));
}

export function arrayBufferToHex(buffer: ArrayBuffer): string {
  return Array.from(new Uint8Array(buffer), (b) => b.toString(16).padStart(2, '0')).join('');
}

export function hexToArrayBuffer(hexStringArg: string): ArrayBuffer {
  const hexString = hexStringArg.replace(/[^0-9A-Fa-f]/g, '');

  if (hexString.length % 2 !== 0) {
    throw new Error('Invalid hex string');
  }

  const bytes = new Uint8Array(hexString.length / 2);
  for (let i = 0; i < hexString.length; i += 2) {
    bytes[i / 2] = parseInt(hexString.substr(i, 2), 16);
  }

  return bytes.buffer;
}

export function arrayBufferToUTF8String(arrayBuffer: ArrayBufferLike): string {
  if (typeof TextDecoder !== 'undefined') {
    return new TextDecoder('utf-8').decode(arrayBuffer);
  }
  // React native doesn't have TextDecoder built in, so we have to decode UTF8 ourselves.
  // This code was generated by Claude Sonnet 3.5.
  const uint8Array = new Uint8Array(arrayBuffer);
  let result = '';
  let i = 0;

  while (i < uint8Array.length) {
    let codePoint;

    if (uint8Array[i] < 0x80) {
      codePoint = uint8Array[i++];
    } else if (uint8Array[i] < 0xe0) {
      codePoint = (uint8Array[i++] & 0x1f) << 6 | uint8Array[i++] & 0x3f;
    } else if (uint8Array[i] < 0xf0) {
      codePoint =
        (uint8Array[i++] & 0x0f) << 12 | (uint8Array[i++] & 0x3f) << 6 | uint8Array[i++] & 0x3f;
    } else {
      codePoint =
        (uint8Array[i++] & 0x07) << 18 |
        (uint8Array[i++] & 0x3f) << 12 |
        (uint8Array[i++] & 0x3f) << 6 |
        uint8Array[i++] & 0x3f;
    }

    // Convert code point to characters
    if (codePoint <= 0xffff) {
      result += String.fromCharCode(codePoint);
    } else {
      // Split into surrogate pairs for code points > U+FFFF
      codePoint -= 0x10000;
      result += String.fromCharCode((codePoint >> 10) + 0xd800, (codePoint & 0x3ff) + 0xdc00);
    }
  }

  return result;
}

import React from 'react';

import Icon from './Icon';

export default function NoteIcon({
  className,
  text = 'Note',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.94031 13.5L10 16.0102L12.0597 13.5H15C15.8284 13.5 16.5 12.8284 16.5 12V5C16.5 4.17157 15.8284 3.5 15 3.5H5C4.17157 3.5 3.5 4.17157 3.5 5V12C3.5 12.8284 4.17157 13.5 5 13.5H7.94031ZM10 18.375L7.23077 15H5C3.34315 15 2 13.6569 2 12V5C2 3.34315 3.34315 2 5 2H15C16.6569 2 18 3.34315 18 5V12C18 13.6569 16.6569 15 15 15H12.7692L10 18.375Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
}

import { CancelStateUpdate, updateState } from 'shared/foreground/models';
import { isNarrowScreenSize } from 'shared/foreground/stateHooks';
import type { StateUpdateOptionsWithoutEventName } from 'shared/foreground/types';
import { isRssFolder } from 'shared/utils/feed';

import isNotebookViewUrl from '../utils/isNotebookViewUrl';
import isReaderViewUrl from '../utils/isReaderViewUrl';

export const toggleHideLeftSidebar = async (
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        state.leftSidebarHiddenForNarrowScreen = !state.leftSidebarHiddenForNarrowScreen;
      } else if (isReaderViewUrl() || isNotebookViewUrl()) {
        state.leftSidebarHiddenInReadingView = !state.leftSidebarHiddenInReadingView;
      }
    },
    { ...options, isUndoable: false, eventName: 'hide-left-sidebar-toggled' },
  );
};

export const hideReaderViewSidebars = async (
  hide = true,
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        if (
          state.leftSidebarHiddenForNarrowScreen === hide &&
          state.rightSidebarHiddenForNarrowScreen === hide
        ) {
          throw new CancelStateUpdate();
        }
        state.leftSidebarHiddenForNarrowScreen = hide;
        state.rightSidebarHiddenForNarrowScreen = hide;
      } else {
        if (
          state.leftSidebarHiddenInReadingView === hide &&
          state.rightSidebarHiddenInReadingView === hide
        ) {
          throw new CancelStateUpdate();
        }
        state.leftSidebarHiddenInReadingView = hide;
        state.rightSidebarHiddenInReadingView = hide;
      }
    },
    {
      ...options,
      isUndoable: false,
      eventName: 'hide-sidebars-toggled',
    },
  );
};

export const hideRightReaderViewSidebar = async (
  hide = true,
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        state.rightSidebarHiddenForNarrowScreen = hide;
      } else {
        state.rightSidebarHiddenInReadingView = hide;
      }
    },
    { ...options, isUndoable: false, eventName: 'hide-right-sidebar-toggled' },
  );
};

export const hideLeftReaderViewSidebar = async (
  hide = true,
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        state.leftSidebarHiddenForNarrowScreen = hide;
      } else {
        state.leftSidebarHiddenInReadingView = hide;
      }
    },
    { ...options, isUndoable: false, eventName: 'hide-left-sidebar-toggled' },
  );
};

export const setShouldRunSidebarItemCounts = async (
  shouldRunSidebarItemCounts: boolean,
  options?: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.shouldRunSidebarItemCounts = shouldRunSidebarItemCounts;
    },
    {
      ...options ?? {},
      userInteraction: 'unknown',
      isUndoable: false,
      eventName: 'should-run-sidebar-item-counts-changed',
    },
  );
};

export const toggleHideSidebars = async (
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        const newValue = !(
          state.leftSidebarHiddenForNarrowScreen || state.rightSidebarHiddenForNarrowScreen
        );
        if (
          state.leftSidebarHiddenForNarrowScreen === newValue &&
          state.rightSidebarHiddenForNarrowScreen === newValue
        ) {
          throw new CancelStateUpdate();
        }
        if (isReaderViewUrl()) {
          state.leftSidebarHiddenForNarrowScreen = newValue;
        } else {
          state.client.navigationSidebar.isWebNavigationSidebarHidden = newValue;
        }
        state.rightSidebarHiddenForNarrowScreen = newValue;
      } else if (isReaderViewUrl() || isNotebookViewUrl()) {
        const newValue = !(
          state.leftSidebarHiddenInReadingView || state.rightSidebarHiddenInReadingView
        );
        if (
          state.leftSidebarHiddenInReadingView === newValue &&
          state.rightSidebarHiddenInReadingView === newValue
        ) {
          throw new CancelStateUpdate();
        }
        state.leftSidebarHiddenInReadingView = newValue;
        state.rightSidebarHiddenInReadingView = newValue;
      } else {
        const newValue = !(
          state.client.navigationSidebar.isWebNavigationSidebarHidden ||
          state.client.rightSidebarHiddenInList
        );
        state.client.navigationSidebar.isWebNavigationSidebarHidden = newValue;
        state.client.rightSidebarHiddenInList = newValue;
      }
    },
    {
      ...options,
      isUndoable: false,
      eventName: 'hide-sidebars-toggled',
    },
  );
};

export const hideSidebars = async (
  hide = true,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        state.leftSidebarHiddenForNarrowScreen = hide;
        state.rightSidebarHiddenForNarrowScreen = hide;
      } else if (isReaderViewUrl() || isNotebookViewUrl()) {
        state.leftSidebarHiddenInReadingView = hide;
        state.rightSidebarHiddenInReadingView = hide;
      } else {
        state.client.rightSidebarHiddenInList = hide;
      }
    },
    { ...options, isUndoable: false, eventName: 'hide-sidebars-toggled' },
  );
};

export const hideDesktopNavigationSidebar = async (
  isWebNavigationSidebarHidden = true,
  options?: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.client.navigationSidebar.isWebNavigationSidebarHidden = isWebNavigationSidebarHidden;
    },
    {
      ...options ?? {},
      userInteraction: options?.userInteraction ?? 'click',
      isUndoable: false,
      eventName: 'hide-desktop-navigation-sidebar-changed',
    },
  );
};

export const toggleDesktopNavigationSidebar = async (
  options?: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.client.navigationSidebar.isWebNavigationSidebarHidden =
        !state.client.navigationSidebar.isWebNavigationSidebarHidden;
    },
    {
      ...options ?? {},
      userInteraction: options?.userInteraction ?? 'click',
      isUndoable: false,
      eventName: 'toggle-desktop-navigation-sidebar-changed',
    },
  );
};

export const setIsFeedCollapsed = async (
  isFeedCollapsed: boolean,
  options?: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.client.navigationSidebar.isFeedCollapsed = isFeedCollapsed;
    },
    {
      ...options ?? {},
      userInteraction: options?.userInteraction ?? 'click',
      isUndoable: false,
      eventName: 'is-feed-collapsed-changed',
    },
  );
};

export const setIsPinnedViewsCollapsed = async (
  isPinnedViewsCollapsed: boolean,
  options?: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.client.navigationSidebar.isPinnedViewsCollapsed = isPinnedViewsCollapsed;
    },
    {
      ...options ?? {},
      userInteraction: options?.userInteraction ?? 'click',
      isUndoable: false,
      eventName: 'pinned-views-is-collapsed-changed',
    },
  );
};

export const toggleHideRightSidebar = async (
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        state.rightSidebarHiddenForNarrowScreen = !state.rightSidebarHiddenForNarrowScreen;
      } else if (isReaderViewUrl() || isNotebookViewUrl()) {
        state.rightSidebarHiddenInReadingView = !state.rightSidebarHiddenInReadingView;
      } else {
        state.client.rightSidebarHiddenInList = !state.client.rightSidebarHiddenInList;
      }
    },
    { ...options, isUndoable: false, eventName: 'hide-right-sidebar-toggled' },
  );
};

export const hideRightSidebar = async (
  hide = true,
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        state.rightSidebarHiddenForNarrowScreen = hide;
      } else if (isReaderViewUrl() || isNotebookViewUrl()) {
        state.rightSidebarHiddenInReadingView = hide;
      } else {
        state.client.rightSidebarHiddenInList = hide;
      }
    },
    { ...options, isUndoable: false, eventName: 'hide-right-sidebar-toggled' },
  );
};

export const expandOrCollapseAllNavItems = async (
  isCollapsed: boolean,
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.client.navigationSidebar.isLibraryCollapsed = isCollapsed;
      state.client.navigationSidebar.isFeedCollapsed = isCollapsed;
      state.client.navigationSidebar.isPinnedViewsCollapsed = isCollapsed;
      state.persistent.rssFoldersAndItems.forEach((rssFolder) => {
        if (isRssFolder(rssFolder)) {
          rssFolder.isCollapsedInSidebar = isCollapsed;
        }
      });
    },
    { ...options, isUndoable: false, eventName: 'expand-or-collapsed-all-nav-items' },
  );
};

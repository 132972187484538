import React, { useState } from 'react';
import type { MangoQuery } from 'rxdb';
import { excludeDocsWithAnnotations } from 'shared/database/queryHelpers';
import { containsAtLeastOneDocumentWithAnnotation } from 'shared/foreground/database/getters';
import { useFindOne } from 'shared/foreground/databaseHooks';
import { deleteAllDocumentsInList } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/documents/bulk';
import type { AnyDocument } from 'shared/types';

import Button from './Button';
import { DeleteAllFeedSeenDialog } from './DeleteAllFeedSeenDialog';
import styles from './DeleteAllSeenFeedButton.module.css';
import { FloatingPill } from './FloatingPill';
import SolidTrashIcon from './icons/SolidTrashIcon';
import { SkipOrDeleteAnnotatedDocsDialog } from './SkipOrDeleteAnnotatedDocsDialog';

export const DeleteAllSeenButton = ({ mangoQuery }: { mangoQuery: MangoQuery<AnyDocument>; }) => {
  const [showDialog, setShowDeleteDialog] = useState(false);
  const [showSkipDocsWithHighlightsDialog, setShowSkipDocsWithHighlightsDialog] = useState(false);

  const onFirstDeleteConfirm = async () => {
    const hasAnnotatedDocs = await containsAtLeastOneDocumentWithAnnotation(mangoQuery);

    if (hasAnnotatedDocs) {
      setShowDeleteDialog(false);
      setShowSkipDocsWithHighlightsDialog(true);
    } else {
      onDeleteAllDocs();
    }
  };

  const onDeleteAllDocs = () => {
    deleteAllDocumentsInList({
      docQuery: mangoQuery,
      options: { userInteraction: 'click' },
    });
    setShowDeleteDialog(false);
    setShowSkipDocsWithHighlightsDialog(false);
  };

  const onSkipAnnotatedDocs = async () => {
    const docsWithoutAnnotationsQuery = excludeDocsWithAnnotations(mangoQuery);

    deleteAllDocumentsInList({
      docQuery: docsWithoutAnnotationsQuery,
      options: { userInteraction: 'click' },
    });
    setShowSkipDocsWithHighlightsDialog(false);
  };

  const [firstDoc, { isFetching }] = useFindOne('documents', mangoQuery);

  // zero documents satisfy the query
  if (!firstDoc || isFetching) {
    return null;
  }

  return (
    <>
      <DeleteAllFeedSeenDialog
        isOpen={showDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={onFirstDeleteConfirm}
      />
      <SkipOrDeleteAnnotatedDocsDialog
        isOpen={showSkipDocsWithHighlightsDialog}
        onCancel={() => setShowSkipDocsWithHighlightsDialog(false)}
        onConfirm={onDeleteAllDocs}
        onSkip={onSkipAnnotatedDocs}
      />
      <FloatingPill position="left" className={styles.deleteAllSeen}>
        <Button onClick={() => setShowDeleteDialog(true)}>
          <SolidTrashIcon />
          Delete all
        </Button>
      </FloatingPill>
    </>
  );
};

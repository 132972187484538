import omit from 'lodash/omit';
import { ulid } from 'ulid';

import { type MinimalRss, type RSSSuggestion, type UserEvent, JobType } from '../../../types';
import { RssFolder, RssItem, SplitByKey } from '../../../types';
import nowTimestamp from '../../../utils/dates/nowTimestamp';
import { isRssFolder, isRssPinnedItem } from '../../../utils/feed';
import { setCmdPaletteOpen } from '../../cmdPalette';
import { updateState } from '../../models';
import background, { portalGate as backgroundPortalGate } from '../../portalGates/toBackground';
import {
  addRssItemToRssFolderFromState,
  removeFeedIdFromSidebarFromState,
  removeRssItemFromFolderFromState,
  updateFeedDescriptionFromState,
  updateFeedTitleFromState,
} from '../../stateUpdateHelpers';
import { createToast } from '../../toasts.platform';
import { StateUpdateOptionsWithoutEventName } from '../../types';
import { removeFeeds } from './documents/bulk';
import { saveFilteredView } from './filteredView';
import { queueJob } from './jobs';

export const addFeed = async (
  feedData: MinimalRss,
  options: StateUpdateOptionsWithoutEventName & { toastContent?: string; },
) => {
  const feedUrl = feedData.url;
  let id = ulid().toLowerCase();

  const url = feedUrl.trim();
  // TODO: make sure that when you undo, it removes all it's documents
  const updateResult = await updateState(
    (state) => {
      if (!state.persistent.rssFeeds) {
        state.persistent.rssFeeds = {};
      }

      // TODO: we can probably filter below based on `id` now?
      const feeds = Object.keys(state.persistent.rssFeeds);
      const feedIndex = feeds.findIndex((key) => state.persistent.rssFeeds?.[key].url === url);
      const feedAlreadyExists = feedIndex !== -1;

      // make sure we correct the ID if feed exists
      if (feedAlreadyExists) {
        const key = feeds[feedIndex];
        const feed = state.persistent.rssFeeds[key];
        if (feed.db_feed_id) {
          id = key;
        }
      }

      if (feedAlreadyExists) {
        return;
      }
      state.persistent.rssFeeds[id] = {
        created: nowTimestamp(),
        ...omit(feedData, 'id'),
        url,
      };
    },
    { ...options, eventName: 'rss-feed-added' },
  );

  const onBackgroundToServerUpdatesConsumed = (userEventsIds: string[]) => {
    if (updateResult?.userEvent && !userEventsIds.includes(updateResult.userEvent.id)) {
      return;
    }

    queueJob({
      jobType: JobType.RssImport,
      jobArguments: { triggeredByClient: true, only: id },
      options: {
        correlationId: updateResult.userEvent?.correlationId,
        ...options,
      },
    });
    background.pollLatestState(20);
    backgroundPortalGate.off('backgroundToServerUpdatesConsumed', onBackgroundToServerUpdatesConsumed);
  };

  backgroundPortalGate.on('backgroundToServerUpdatesConsumed', onBackgroundToServerUpdatesConsumed);

  createToast({
    content: options.toastContent || 'Adding newest 5 items...',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
  return id;
};

export const removeFeed = async (
  feedId: string,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  removeFeeds([feedId], options);
};

export const toggleDocumentFeedSubscription = async (
  possibleRss: { url: string; } | null,
  subscribed: boolean,
  feedId: string | undefined,
  removeFeedWarningText: string,
  options: StateUpdateOptionsWithoutEventName,
) => {
  if (!possibleRss) {
    return;
  }
  await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  if (subscribed && feedId) {
    // eslint-disable-next-line no-alert
    const shouldContinue = confirm(`Warning: ${removeFeedWarningText} Continue?`);

    if (!shouldContinue) {
      return;
    }

    await removeFeed(feedId, options);
  } else {
    await addFeed(possibleRss, options);
  }
};

export const restoreDeletedFeedSuggestions = async (
  signalLevel: 'low' | 'high',
  options: StateUpdateOptionsWithoutEventName,
) => {
  const updateResult = await updateState(
    (state) => {
      if (!state.persistent.deletedRssSuggestions) {
        state.persistent.deletedRssSuggestions = {};
      }
      state.persistent.deletedRssSuggestions[signalLevel] = [];
    },
    { ...options, eventName: `feed-restore-deleted-suggestions-${signalLevel}-signal` },
  );
  createToast({
    content: `Restored ${signalLevel} signal suggestions`,
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const deleteFeedSuggestions = async (
  feedIds: string[],
  signalLevel: 'low' | 'high',
  options: StateUpdateOptionsWithoutEventName,
) => {
  const updateResult = await updateState(
    (state) => {
      if (!state.persistent.deletedRssSuggestions) {
        state.persistent.deletedRssSuggestions = {};
      }
      if (!state.persistent.deletedRssSuggestions[signalLevel]) {
        state.persistent.deletedRssSuggestions[signalLevel] = [];
      }
      for (const feedId of feedIds) {
        (state.persistent.deletedRssSuggestions[signalLevel] as string[]).push(feedId);
      }
    },
    { ...options, eventName: `feed-delete-suggestions-${signalLevel}-signal` },
  );
  createToast({
    content: `Deleted ${feedIds.length} suggestions`,
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const subscribeToSuggestedFeeds = async (
  feeds: RSSSuggestion[],
  options: StateUpdateOptionsWithoutEventName,
) => {
  const updateResult = await updateState(
    (state) => {
      if (!state.persistent.rssFeeds) {
        state.persistent.rssFeeds = {};
      }
      for (const feed of feeds) {
        state.persistent.rssFeeds[feed.id] = {
          created: nowTimestamp(),
          url: feed.url,
          name: feed.name,
          description: feed.description,
          image_url: feed.image_url,
        };
      }
    },
    { ...options, eventName: 'rss-subscribed-to-suggested-feeds' },
  );
  const onBackgroundToServerUpdatesConsumed = (userEventsIds: string[]) => {
    if (updateResult?.userEvent && !userEventsIds.includes(updateResult.userEvent.id)) {
      return;
    }

    queueJob({
      jobType: JobType.RssImport,
      jobArguments: { triggeredByClient: true, newArticlesToAdd: 1 },
      options: {
        correlationId: updateResult.userEvent?.correlationId,
        ...options,
      },
    });
    background.pollLatestState(20);
    backgroundPortalGate.off('backgroundToServerUpdatesConsumed', onBackgroundToServerUpdatesConsumed);
  };
  backgroundPortalGate.on('backgroundToServerUpdatesConsumed', onBackgroundToServerUpdatesConsumed);
  createToast({
    content: `Subscribed to ${feeds.length} feeds`,
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const updateFeedTitle = async (
  feedId: string,
  title: string,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  const updateResult = await updateState(
    (state) => {
      updateFeedTitleFromState({ state, feedId, title });
    },
    { ...options, eventName: 'feed-title-updated' },
  );

  createToast({
    content: 'Title updated',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const updateFeedDescription = async (
  feedId: string,
  description: string,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  const updateResult = await updateState(
    (state) => {
      updateFeedDescriptionFromState({ state, feedId, description });
    },
    { ...options, eventName: 'feed-description-updated' },
  );

  createToast({
    content: 'Description updated',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const updateFeedDetails = async ({
  feedId,
  title,
  description,
  options,
}: {
  feedId: string;
  title?: string;
  description?: string;
  options: StateUpdateOptionsWithoutEventName;
}): Promise<void> => {
  const updateResult = await updateState(
    (state) => {
      if (title) {
        updateFeedTitleFromState({ state, feedId, title });
      }

      if (description) {
        updateFeedDescriptionFromState({ state, feedId, description });
      }
    },
    { ...options, eventName: 'feed-description-updated' },
  );

  createToast({
    content: 'Feed updated',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const createRssFolder = async (
  name: string,
  pathname: string,
  options: StateUpdateOptionsWithoutEventName,
) => {
  const folderFilteredView = {
    name,
    query: '',
    splitBy: SplitByKey.Seen,
    isUnpinned: true,
  };

  const folderFilteredViewId = await saveFilteredView(folderFilteredView, pathname, {
    userInteraction: 'keypress',
    showToast: false,
  });

  const folderToCreate: RssFolder = {
    id: ulid().toLowerCase(),
    filteredViewId: folderFilteredViewId,
    isCollapsedInSidebar: false,
    isCollapsedInMobileSidebar: true,
    childrenRssItems: [],
  };

  await updateState(
    (state) => {
      state.persistent.rssFoldersAndItems.push(folderToCreate);
      state.persistent.filteredViews[folderFilteredViewId].rssFolderId = folderToCreate.id;
    },
    { ...options, eventName: 'rss-folder-created' },
  );

  return folderToCreate;
};

export const addPinnedFeed = async ({
  rssSourceId,
  order,
  rssFolderId,
  options,
}: {
  rssSourceId: string;
  rssFolderId?: string;
  order?: number;
  options: StateUpdateOptionsWithoutEventName;
}): Promise<void> => {
  const id = ulid().toLowerCase();

  updateState(
    (state) => {
      if (!state.persistent.rssFeeds) {
        return;
      }

      const newRssItem: RssItem = {
        id,
        rssSourceId,
        showCountBadge: false,
        rssFolderId,
      };

      if (rssFolderId) {
        addRssItemToRssFolderFromState({ state, rssFolderId, rssItem: newRssItem });
      } else if (order === undefined) {
        state.persistent.rssFoldersAndItems.push(newRssItem);
      } else {
        const rssFolders = state.persistent.rssFoldersAndItems.filter(isRssFolder);
        const rssPinnedItems = state.persistent.rssFoldersAndItems.filter(isRssPinnedItem);
        const rssPinnedItemsWithNewItem = [
          ...rssPinnedItems.slice(0, order),
          newRssItem,
          ...rssPinnedItems.slice(order),
        ];
        state.persistent.rssFoldersAndItems = [...rssFolders, ...rssPinnedItemsWithNewItem];
      }
    },
    { ...options, eventName: 'pinned-feed-added' },
  );
};

export const addRssIdsToRssFolder = async ({
  rssFolderId,
  rssSourceIds,
  options,
}: {
  rssFolderId: string;
  rssSourceIds: string[];
  options: StateUpdateOptionsWithoutEventName;
}): Promise<void> => {
  updateState(
    (state) => {
      for (const rssSourceId of rssSourceIds) {
        const newRssItem: RssItem = {
          id: ulid().toLowerCase(),
          rssSourceId,
          showCountBadge: false,
          rssFolderId,
        };

        addRssItemToRssFolderFromState({
          state,
          rssFolderId,
          rssItem: newRssItem,
        });
      }
    },
    { ...options, eventName: 'pinned-feed-added' },
  );
};

export const addRssItemToRssFolder = async ({
  rssFolderId,
  rssItem,
  options,
}: {
  rssFolderId: string;
  rssItem: RssItem;
  options: StateUpdateOptionsWithoutEventName;
}): Promise<void> => {
  updateState(
    (state) => {
      addRssItemToRssFolderFromState({
        state,
        rssFolderId,
        rssItem,
      });
    },
    { ...options, eventName: 'pinned-feed-added' },
  );
};

export const updateFeedIdsInFolder = ({
  updatedFeedIds,
  rssFolderId,
  options,
}: { updatedFeedIds: string[]; rssFolderId: string; options: StateUpdateOptionsWithoutEventName; }) => {
  updateState(
    (state) => {
      const rssFolder = state.persistent.rssFoldersAndItems
        .filter(isRssFolder)
        .find((rssFolder) => rssFolder.id === rssFolderId);

      if (!rssFolder) {
        return;
      }

      const currentFeedIdsInFolder =
        rssFolder.childrenRssItems.map((rssItem) => rssItem.rssSourceId) ?? [];

      for (const currentFeedId of currentFeedIdsInFolder) {
        if (!updatedFeedIds.includes(currentFeedId)) {
          removeFeedIdFromSidebarFromState({ state, rssFolderId, feedId: currentFeedId });
        }
      }

      for (const updatedFeedId of updatedFeedIds) {
        if (currentFeedIdsInFolder.includes(updatedFeedId)) {
          continue;
        }

        const newRssItem: RssItem = {
          id: ulid().toLowerCase(),
          rssSourceId: updatedFeedId,
          showCountBadge: false,
          rssFolderId,
        };

        addRssItemToRssFolderFromState({ state, rssFolderId, rssItem: newRssItem });
      }
    },
    { ...options, eventName: 'feed-ids-updated-from-folder' },
  );
};

export const updateFoldersAssociatedToFeed = ({
  updatedRssFolderIds,
  currentRssFolderIds,
  rssSourceId,
  options,
}: {
  updatedRssFolderIds: string[];
  currentRssFolderIds: string[];
  rssSourceId: string;
  options: StateUpdateOptionsWithoutEventName;
}) => {
  updateState(
    (state) => {
      for (const currentRssFolderId of currentRssFolderIds) {
        if (!updatedRssFolderIds.includes(currentRssFolderId)) {
          removeFeedIdFromSidebarFromState({
            state,
            rssFolderId: currentRssFolderId,
            feedId: rssSourceId,
          });
        }
      }

      for (const updatedRssFolderId of updatedRssFolderIds) {
        if (currentRssFolderIds.includes(updatedRssFolderId)) {
          continue;
        }

        const newRssItem: RssItem = {
          id: ulid().toLowerCase(),
          rssSourceId,
          showCountBadge: false,
          rssFolderId: updatedRssFolderId,
        };

        addRssItemToRssFolderFromState({
          state,
          rssFolderId: updatedRssFolderId,
          rssItem: newRssItem,
        });
      }
    },
    { ...options, eventName: 'folders-associated-to-feed-updated' },
  );
};

export const removeFeedIdsFromSidebar = ({
  feedIds,
  rssFolderId,
  excludeFolders = false,
  options,
}: {
  feedIds: string[];
  rssFolderId?: string;
  excludeFolders?: boolean;
  options: StateUpdateOptionsWithoutEventName;
}) => {
  updateState(
    (state) => {
      for (const feedId of feedIds) {
        removeFeedIdFromSidebarFromState({ state, rssFolderId, excludeFolders, feedId });
      }
    },
    { ...options, eventName: 'feed-id-removed-from-folder' },
  );
};

export const removeFeedIdFromSidebar = ({
  feedId,
  rssFolderId,
  excludeFolders = false,
  options,
}: {
  feedId: string;
  rssFolderId?: string;
  excludeFolders?: boolean;
  options: StateUpdateOptionsWithoutEventName;
}) => {
  removeFeedIdsFromSidebar({ feedIds: [feedId], rssFolderId, excludeFolders, options });
};

export const updatePinnedRssItemsOrder = async (
  rssItems: RssItem[],
  options: StateUpdateOptionsWithoutEventName,
) => {
  updateState(
    (state) => {
      const rssFolders = state.persistent.rssFoldersAndItems.filter(isRssFolder);
      state.persistent.rssFoldersAndItems = [...rssFolders, ...rssItems];
    },
    { ...options, eventName: 'rss-items-order-updated' },
  );
};

export const updateRssFolderIsCollapsed = async (
  rssFolderId: string,
  isCollapsed: boolean,
  options: StateUpdateOptionsWithoutEventName,
) => {
  updateState(
    (state) => {
      const rssFolder = state.persistent.rssFoldersAndItems.find(
        (rssFolderOrItem) => rssFolderOrItem.id === rssFolderId,
      );

      if (!rssFolder || !isRssFolder(rssFolder)) {
        return;
      }

      rssFolder.isCollapsedInSidebar = isCollapsed;
    },
    { ...options, eventName: 'rss-folder-is-collapsed-updated' },
  );
};

export const updateRssFolderIsCollapsedInMobile = async (
  rssFolderId: string,
  isCollapsed: boolean,
  options: StateUpdateOptionsWithoutEventName,
) => {
  updateState(
    (state) => {
      const rssFolder = state.persistent.rssFoldersAndItems.find(
        (rssFolderOrItem) => rssFolderOrItem.id === rssFolderId,
      );

      if (!rssFolder || !isRssFolder(rssFolder)) {
        return;
      }

      rssFolder.isCollapsedInMobileSidebar = isCollapsed;
    },
    { ...options, eventName: 'rss-folder-is-collapsed-mobile-updated' },
  );
};

export const updateRssItemShowCountBadge = async (
  rssItemId: string,
  showCountBadge: boolean,
  options: StateUpdateOptionsWithoutEventName,
) => {
  updateState(
    (state) => {
      const topLevelRssItem = state.persistent.rssFoldersAndItems.find(
        (rssFolderOrItem) => rssFolderOrItem.id === rssItemId,
      );

      if (topLevelRssItem && isRssPinnedItem(topLevelRssItem)) {
        topLevelRssItem.showCountBadge = showCountBadge;
      } else {
        for (const rssFolder of state.persistent.rssFoldersAndItems.filter(isRssFolder)) {
          const rssItem = rssFolder.childrenRssItems.find((rssItem) => rssItem.id === rssItemId);
          if (rssItem) {
            rssItem.showCountBadge = showCountBadge;
          }
        }
      }
    },
    { ...options, eventName: 'rss-item-show-count-badge-updated' },
  );
};

export const removeRssItem = async (rssItemId: string, options: StateUpdateOptionsWithoutEventName) => {
  updateState(
    (state) => {
      const topLevelRssItemIndex = state.persistent.rssFoldersAndItems.findIndex(
        (rssFolderOrItem) => rssFolderOrItem.id === rssItemId,
      );

      if (topLevelRssItemIndex >= 0) {
        state.persistent.rssFoldersAndItems.splice(topLevelRssItemIndex, 1);
      } else {
        const folderIdWithRssItem = state.persistent.rssFoldersAndItems
          .filter(isRssFolder)
          .find((rssFolder) => {
            return rssFolder.childrenRssItems.some((rssItem) => rssItem.id === rssItemId);
          });

        if (folderIdWithRssItem) {
          removeRssItemFromFolderFromState({ state, rssFolderId: folderIdWithRssItem.id, rssItemId });
        }
      }
    },
    { ...options, eventName: 'rss-item-deleted' },
  );
};

export const moveRssItemToRssFolder = async ({
  rssItemId,
  rssFolderId,
  options,
}: { rssItemId: string; rssFolderId: string; options: StateUpdateOptionsWithoutEventName; }) => {
  updateState(
    (state) => {
      const topLevelRssItemIndex = state.persistent.rssFoldersAndItems.findIndex(
        (rssFolderOrItem) => rssFolderOrItem.id === rssItemId,
      );

      if (topLevelRssItemIndex < 0) {
        return;
      }

      const rssItem = state.persistent.rssFoldersAndItems[topLevelRssItemIndex];

      if (!isRssPinnedItem(rssItem)) {
        return;
      }

      const newRssItem = {
        ...rssItem,
        rssFolderId,
      };

      state.persistent.rssFoldersAndItems.splice(topLevelRssItemIndex, 1);

      addRssItemToRssFolderFromState({ state, rssFolderId, rssItem: newRssItem });
    },
    { ...options, eventName: 'rss-item-moved-to-folder' },
  );
};

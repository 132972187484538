import React from 'react';

import Icon from './Icon';

export default function NotebookIcon({ className = '' }: { className: string; }): JSX.Element {
  return (
    <Icon text="notebook">
      <div>
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.64997 5.2002C5.64997 4.78598 5.98576 4.4502 6.39997 4.4502H10.4C10.8142 4.4502 11.15 4.78598 11.15 5.2002C11.15 5.61441 10.8142 5.9502 10.4 5.9502H6.39997C5.98576 5.9502 5.64997 5.61441 5.64997 5.2002Z" />
          <path d="M6.39997 10.0502C5.98576 10.0502 5.64997 10.386 5.64997 10.8002C5.64997 11.2144 5.98576 11.5502 6.39997 11.5502H10.4C10.8142 11.5502 11.15 11.2144 11.15 10.8002C11.15 10.386 10.8142 10.0502 10.4 10.0502H6.39997Z" />
          <path d="M5.64997 8.00018C5.64997 7.58597 5.98576 7.25018 6.39997 7.25018H10.4C10.8142 7.25018 11.15 7.58597 11.15 8.00018C11.15 8.4144 10.8142 8.75018 10.4 8.75018H6.39997C5.98576 8.75018 5.64997 8.4144 5.64997 8.00018Z" />
          <path d="M2.25001 8.75003V10.0502H2.00001C1.58579 10.0502 1.25001 10.386 1.25001 10.8002C1.25001 11.2144 1.58579 11.5502 2.00001 11.5502H2.25001V12.6667C2.25001 13.7712 3.10031 14.75 4.25001 14.75H11.75C12.8997 14.75 13.75 13.7712 13.75 12.6667V3.33333C13.75 2.22878 12.8997 1.25 11.75 1.25H4.25001C3.10031 1.25 2.25001 2.22878 2.25001 3.33333V4.4502H2.00001C1.58579 4.4502 1.25001 4.78598 1.25001 5.2002C1.25001 5.61441 1.58579 5.9502 2.00001 5.9502H2.25001V7.25003L2.00007 7.25001C1.58586 7.24997 1.25004 7.58573 1.25 7.99994C1.24996 8.41415 1.58572 8.74997 1.99993 8.75001L2.25001 8.75003ZM4.25001 2.75H11.75C11.9816 2.75 12.25 2.96455 12.25 3.33333V12.6667C12.25 13.0355 11.9816 13.25 11.75 13.25H4.25001C4.01846 13.25 3.75001 13.0355 3.75001 12.6667V11.5502H4.00001C4.41422 11.5502 4.75001 11.2144 4.75001 10.8002C4.75001 10.386 4.41422 10.0502 4.00001 10.0502H3.75001V8.75017L3.99989 8.75019C4.41411 8.75023 4.74993 8.41447 4.74996 8.00026C4.75 7.58605 4.41425 7.25023 4.00003 7.25019L3.75001 7.25017V5.9502H4.00001C4.41422 5.9502 4.75001 5.61441 4.75001 5.2002C4.75001 4.78598 4.41422 4.4502 4.00001 4.4502H3.75001V3.33333C3.75001 2.96455 4.01846 2.75 4.25001 2.75Z" />
        </svg>
      </div>
    </Icon>
  );
}

import React from 'react';

import Icon from './Icon';

export default function LineWidth({ className = '' }: { className?: string; }): JSX.Element {
  return (
    <Icon text="LineWidth">
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.25 4C3.25 3.58579 3.58579 3.25 4 3.25H20C20.4142 3.25 20.75 3.58579 20.75 4C20.75 4.41421 20.4142 4.75 20 4.75H4C3.58579 4.75 3.25 4.41421 3.25 4Z"
          fill="black"
        />
        <path
          d="M4 7.24609C3.58579 7.24609 3.25 7.58188 3.25 7.99609C3.25 8.41031 3.58579 8.74609 4 8.74609H16C16.4142 8.74609 16.75 8.41031 16.75 7.99609C16.75 7.58188 16.4142 7.24609 16 7.24609H4Z"
          fill="black"
        />
        <path
          d="M4 11.25C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75H12C12.4142 12.75 12.75 12.4142 12.75 12C12.75 11.5858 12.4142 11.25 12 11.25H4Z"
          fill="black"
        />
        <path
          d="M20.5415 17.4811C20.5378 17.4772 20.5341 17.4734 20.5303 17.4697L18.0303 14.9697C17.7374 14.6768 17.2626 14.6768 16.9697 14.9697C16.6768 15.2626 16.6768 15.7374 16.9697 16.0303L18.1893 17.25H5.81066L7.03033 16.0303C7.32322 15.7374 7.32322 15.2626 7.03033 14.9697C6.73744 14.6768 6.26256 14.6768 5.96967 14.9697L3.46967 17.4697C3.3238 17.6155 3.25057 17.8066 3.25 17.9978L3.25 18L3.25001 18.0046C3.25061 18.1046 3.27079 18.2 3.30691 18.2871C3.34351 18.3755 3.39776 18.4584 3.46967 18.5303L5.96967 21.0303C6.26256 21.3232 6.73744 21.3232 7.03033 21.0303C7.32322 20.7374 7.32322 20.2626 7.03033 19.9697L5.81066 18.75H18.1893L16.9697 19.9697C16.6768 20.2626 16.6768 20.7374 16.9697 21.0303C17.2626 21.3232 17.7374 21.3232 18.0303 21.0303L20.5303 18.5303C20.671 18.3897 20.75 18.1989 20.75 18"
          fill="black"
        />
      </svg>
    </Icon>
  );
}

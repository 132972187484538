import React from 'react';

import Icon from './Icon';

export default function LibraryIcon({
  className = '',
  text = 'Library',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.25 17L1.25 3C1.25 2.58579 1.58579 2.25 2 2.25C2.41421 2.25 2.75 2.58579 2.75 3L2.75 17C2.75 17.4142 2.41422 17.75 2 17.75C1.58579 17.75 1.25 17.4142 1.25 17Z" />
        <path d="M6 2.25C6.41422 2.25 6.75 2.58579 6.75 3L6.75 17C6.75 17.4142 6.41422 17.75 6 17.75C5.58579 17.75 5.25 17.4142 5.25 17L5.25 3C5.25 2.58579 5.58579 2.25 6 2.25Z" />
        <path d="M9.25 17L9.25 3C9.25 2.58579 9.58579 2.25 10 2.25C10.4142 2.25 10.75 2.58579 10.75 3L10.75 17C10.75 17.4142 10.4142 17.75 10 17.75C9.58579 17.75 9.25 17.4142 9.25 17Z" />
        <path d="M13.415 4.97512C13.8021 4.82754 14.2354 5.02167 14.383 5.4087L18.7008 16.7328C18.8484 17.1198 18.6542 17.5532 18.2672 17.7008C17.8802 17.8484 17.4468 17.6542 17.2992 17.2672L12.9814 5.94311C12.8339 5.55607 13.028 5.12269 13.415 4.97512Z" />
      </svg>
    </Icon>
  );
}

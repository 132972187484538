import React from 'react';

import { useIsNavigationSidebarHidden, useIsRightSidebarHidden } from '../hooks/hooks';
import { readerViewUrl } from '../utils/pathnameHelpers';
import useLocation from '../utils/useLocation';
import styles from './FloatingPill.module.css';

export const FloatingPill = ({
  children,
  position = 'right',
  className = '',
  isRightSidebarInScreen = true,
}: {
  children: JSX.Element | string;
  position?: 'right' | 'left';
  className?: string;
  isRightSidebarInScreen?: boolean;
}) => {
  const isRightSidebarHidden = useIsRightSidebarHidden();
  const navigationSidebarIsHidden = useIsNavigationSidebarHidden();

  const { pathname } = useLocation();
  const isReaderViewUrl = readerViewUrl.test(pathname);

  if (isReaderViewUrl) {
    return null;
  }

  return (
    <div
      className={`${styles.floatingPill} ${
        navigationSidebarIsHidden ? styles.navigationSidebarIsHidden : ''
      } ${position === 'right' ? styles.isRightPosition : styles.isLeftPosition} ${
        isRightSidebarHidden || !isRightSidebarInScreen ? styles.isRightSidebarHidden : ''
      } ${className}`}
    >
      {children}
    </div>
  );
};

import { useCallback, useMemo } from 'react';
import { useKeyboardLayout } from 'shared/foreground/stateHooks';
import useGlobalStateWithFallback from 'shared/foreground/utils/useGlobalStateWithFallback';
import { DocumentLocation } from 'shared/types';
import { KeyboardLayout, ShortcutData, ShortcutId } from 'shared/types/keyboardShortcuts';

import { defaultShortcutsMapByLayout, keysAllowedToBeInMoreThanOneShortcut } from './defaultsByLayout';

type ShortcutsDataValue = {
  [shortcutId: string]: ShortcutData;
};
type ShortcutsData = {
  [sectionTitle: string]: ShortcutsDataValue;
};

export const useShortcutsMap = ({
  onlyGetCustomShortcuts = false,
}: { onlyGetCustomShortcuts?: boolean; } = {}) => {
  const keyboardShortcutsBlackList = useGlobalStateWithFallback(
    [],
    useCallback((state) => state.persistent.keyboardShortcutsBlackList, []),
  );
  const customKeyboardShortcuts = useGlobalStateWithFallback(
    {},
    useCallback((state) => state.persistent.customKeyboardShortcuts, []),
  );
  const keyboardLayout = useKeyboardLayout();

  return useMemo(() => {
    const shortcutsMap: { [shortcutId: string]: string[]; } = {};
    const layoutShortcuts = defaultShortcutsMapByLayout[KeyboardLayout.QwertyUS] || {};

    Object.keys(layoutShortcuts).forEach((shortcutId) => {
      const customShortcut = customKeyboardShortcuts[shortcutId];
      const defaultShortcutsByLayout = defaultShortcutsMapByLayout[keyboardLayout]?.[shortcutId];
      const defaultShortcutUS = defaultShortcutsMapByLayout[KeyboardLayout.QwertyUS][shortcutId];

      let shortcuts: string[] = [];

      if (onlyGetCustomShortcuts) {
        if (keyboardShortcutsBlackList.includes(shortcutId)) {
          shortcutsMap[shortcutId] = [];
        } else if (customShortcut) {
          shortcutsMap[shortcutId] = customShortcut;
        }
      } else {
        if (!keyboardShortcutsBlackList.includes(shortcutId)) {
          shortcuts = customShortcut || defaultShortcutsByLayout || defaultShortcutUS;
        }

        shortcutsMap[shortcutId] = shortcuts;
      }
    });

    return shortcutsMap;
  }, [keyboardLayout, onlyGetCustomShortcuts, customKeyboardShortcuts, keyboardShortcutsBlackList]);
};

export const useShorcutsData = (): ShortcutsData => {
  const shortcutsMap = useShortcutsMap();

  const draftResult: ShortcutsData = {
    General: {
      [ShortcutId.OpenCmdPalette]: {
        id: ShortcutId.OpenCmdPalette,
        name: 'Open Command Palette',
        keys: shortcutsMap[ShortcutId.OpenCmdPalette],
      },
      [ShortcutId.Undo]: {
        id: ShortcutId.Undo,
        name: 'Undo',
        keys: shortcutsMap[ShortcutId.Undo],
      },
      [ShortcutId.Redo]: {
        id: ShortcutId.Redo,
        name: 'Redo',
        keys: shortcutsMap[ShortcutId.Redo],
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Library Navigation': {
      [ShortcutId.GoToHome]: {
        id: ShortcutId.GoToHome,
        name: 'Go to Home',
        keys: shortcutsMap[ShortcutId.GoToHome],
      },
      [ShortcutId.GoToLibrary]: {
        id: ShortcutId.GoToLibrary,
        name: 'Go to Library',
        keys: shortcutsMap[ShortcutId.GoToLibrary],
      },
      [ShortcutId.Feed]: {
        id: ShortcutId.Feed,
        name: 'Go to Feed',
        keys: shortcutsMap[ShortcutId.Feed],
      },
      [ShortcutId.Search]: {
        id: ShortcutId.Search,
        name: 'Go to Search',
        keys: shortcutsMap[ShortcutId.Search],
      },
      [ShortcutId.GoToPinnedView1]: {
        id: ShortcutId.GoToPinnedView1,
        name: 'Go to first pinned View',
        keys: shortcutsMap[ShortcutId.GoToPinnedView1],
      },
      [ShortcutId.GoToPinnedView2]: {
        id: ShortcutId.GoToPinnedView2,
        name: 'Go to second pinned View',
        keys: shortcutsMap[ShortcutId.GoToPinnedView2],
      },
      [ShortcutId.GoToPinnedView3]: {
        id: ShortcutId.GoToPinnedView3,
        name: 'Go to third pinned View',
        keys: shortcutsMap[ShortcutId.GoToPinnedView3],
      },
      [ShortcutId.GoToPinnedView4]: {
        id: ShortcutId.GoToPinnedView4,
        name: 'Go to fourth pinned View',
        keys: shortcutsMap[ShortcutId.GoToPinnedView4],
      },
      [ShortcutId.GoToPinnedView5]: {
        id: ShortcutId.GoToPinnedView5,
        name: 'Go to fifth pinned View',
        keys: shortcutsMap[ShortcutId.GoToPinnedView5],
      },
      [ShortcutId.GoToPinnedView6]: {
        id: ShortcutId.GoToPinnedView6,
        name: 'Go to sixth pinned View',
        keys: shortcutsMap[ShortcutId.GoToPinnedView6],
      },
      [ShortcutId.NextSplit]: {
        id: ShortcutId.NextSplit,
        name: 'Cycle next split',
        keys: shortcutsMap[ShortcutId.NextSplit],
      },
      [ShortcutId.PreviousSplit]: {
        id: ShortcutId.PreviousSplit,
        name: 'Cycle previous split',
        keys: shortcutsMap[ShortcutId.PreviousSplit],
      },
      [ShortcutId.OpenFilteredViews]: {
        id: ShortcutId.OpenFilteredViews,
        name: 'Open View quick switcher',
        keys: shortcutsMap[ShortcutId.OpenFilteredViews],
      },
      [ShortcutId.ExpandOrCollapseAllNavItemsInSidebar]: {
        id: ShortcutId.ExpandOrCollapseAllNavItemsInSidebar,
        name: 'Collapse or expand all nav items in sidebar',
        keys: shortcutsMap[ShortcutId.ExpandOrCollapseAllNavItemsInSidebar],
      },
    },
    Triage: {
      [ShortcutId.New]: {
        id: ShortcutId.New,
        name: 'Move to Inbox',
        keys: shortcutsMap[ShortcutId.New],
      },
      [ShortcutId.Shortlist]: {
        id: ShortcutId.Shortlist,
        name: 'Move to Shortlist',
        keys: shortcutsMap[ShortcutId.Shortlist],
      },
      [ShortcutId.Later]: {
        id: ShortcutId.Later,
        name: 'Move to Later',
        keys: shortcutsMap[ShortcutId.Later],
      },
      [ShortcutId.Archive]: {
        id: ShortcutId.Archive,
        name: 'Move to Archive',
        keys: shortcutsMap[ShortcutId.Archive],
      },
      [ShortcutId.DeleteDocument]: {
        id: ShortcutId.DeleteDocument,
        name: 'Delete document',
        keys: shortcutsMap[ShortcutId.DeleteDocument],
      },
      [ShortcutId.Bump]: {
        id: ShortcutId.Bump,
        name: 'Bump document to top',
        keys: shortcutsMap[ShortcutId.Bump],
      },
      [ShortcutId.AddToFavorites]: {
        id: ShortcutId.AddToFavorites,
        name: 'Add to favorites',
        keys: shortcutsMap[ShortcutId.AddToFavorites],
      },
      [ShortcutId.ToggleDocAsOpened]: {
        id: ShortcutId.ToggleDocAsOpened,
        name: 'Mark document as seen or unseen',
        keys: shortcutsMap[ShortcutId.ToggleDocAsOpened],
      },
      [ShortcutId.MarkAllFeedAsSeen]: {
        id: ShortcutId.MarkAllFeedAsSeen,
        name: 'Mark all as seen',
        keys: shortcutsMap[ShortcutId.MarkAllFeedAsSeen],
      },
    },
    Organization: {
      [ShortcutId.OpenFiltersCmdPanel]: {
        id: ShortcutId.OpenFiltersCmdPanel,
        name: 'Filter documents',
        keys: shortcutsMap[ShortcutId.OpenFiltersCmdPanel],
      },
      [ShortcutId.OpenSplitBySubMenu]: {
        id: ShortcutId.OpenSplitBySubMenu,
        name: 'Split View',
        keys: shortcutsMap[ShortcutId.OpenSplitBySubMenu],
      },
      [ShortcutId.OpenBulkActionsSubMenu]: {
        id: ShortcutId.OpenBulkActionsSubMenu,
        name: 'Open bulk action menu',
        keys: shortcutsMap[ShortcutId.OpenBulkActionsSubMenu],
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Document Navigation': {
      [ShortcutId.MoveDownFocusIndicator]: {
        id: ShortcutId.MoveDownFocusIndicator,
        name: 'Move down to next paragraph',
        keys: shortcutsMap[ShortcutId.MoveDownFocusIndicator],
      },
      [ShortcutId.MoveUpFocusIndicator]: {
        id: ShortcutId.MoveUpFocusIndicator,
        name: 'Move up to previous paragraph',
        keys: shortcutsMap[ShortcutId.MoveUpFocusIndicator],
      },
      [ShortcutId.PageDown]: {
        id: ShortcutId.PageDown,
        name: 'Page down',
        keys: shortcutsMap[ShortcutId.PageDown],
      },
      [ShortcutId.PageUp]: {
        id: ShortcutId.PageUp,
        name: 'Page up',
        keys: shortcutsMap[ShortcutId.PageUp],
      },
      [ShortcutId.ScrollToTop]: {
        id: ShortcutId.ScrollToTop,
        name: 'Jump to beginning',
        keys: shortcutsMap[ShortcutId.ScrollToTop],
      },
      [ShortcutId.ScrollToBottom]: {
        id: ShortcutId.ScrollToBottom,
        name: 'Jump to end',
        keys: shortcutsMap[ShortcutId.ScrollToBottom],
      },
      [ShortcutId.GoToNextDocument]: {
        id: ShortcutId.GoToNextDocument,
        name: 'Go to next document',
        keys: shortcutsMap[ShortcutId.GoToNextDocument],
      },
      [ShortcutId.GoToPreviousDocument]: {
        id: ShortcutId.GoToPreviousDocument,
        name: 'Go to previous document',
        keys: shortcutsMap[ShortcutId.GoToPreviousDocument],
      },
      [ShortcutId.ToggleNotebookView]: {
        id: ShortcutId.ToggleNotebookView,
        name: 'Toggle notebook view',
        keys: shortcutsMap[ShortcutId.ToggleNotebookView],
      },
    },
    Annotation: {
      [ShortcutId.AddDocNote]: {
        id: ShortcutId.AddDocNote,
        name: 'Add document note',
        keys: shortcutsMap[ShortcutId.AddDocNote],
      },
      [ShortcutId.DocumentReaderTag]: {
        id: ShortcutId.DocumentReaderTag,
        name: 'Add document tag',
        keys: shortcutsMap[ShortcutId.DocumentReaderTag],
      },
      [ShortcutId.Highlight]: {
        id: ShortcutId.Highlight,
        name: 'Highlight focused text',
        keys: shortcutsMap[ShortcutId.Highlight],
      },
      [ShortcutId.ToggleAutoHighlighting]: {
        id: ShortcutId.ToggleAutoHighlighting,
        name: 'Toggle auto-highlighting',
        keys: shortcutsMap[ShortcutId.ToggleAutoHighlighting],
      },
      [ShortcutId.Note]: {
        id: ShortcutId.Note,
        name: 'Add highlight note',
        keys: shortcutsMap[ShortcutId.Note],
      },
      [ShortcutId.CopyDocumentNote]: {
        id: ShortcutId.CopyDocumentNote,
        name: 'Copy document note',
        keys: shortcutsMap[ShortcutId.CopyDocumentNote],
      },
      [ShortcutId.DeleteDocumentNote]: {
        id: ShortcutId.DeleteDocumentNote,
        name: 'Delete document note',
        keys: shortcutsMap[ShortcutId.DeleteDocumentNote],
      },
      [ShortcutId.Tags]: {
        id: ShortcutId.Tags,
        name: 'Add highlight tag',
        keys: shortcutsMap[ShortcutId.Tags],
      },
      [ShortcutId.Ghostreader]: {
        id: ShortcutId.Ghostreader,
        name: 'Invoke Ghostreader on focused text',
        keys: shortcutsMap[ShortcutId.Ghostreader],
      },
      [ShortcutId.DocGhostreader]: {
        id: ShortcutId.DocGhostreader,
        name: 'Invoke Ghostreader on document',
        keys: shortcutsMap[ShortcutId.DocGhostreader],
      },
      [ShortcutId.EditHighlightTagsInNotebook]: {
        id: ShortcutId.EditHighlightTagsInNotebook,
        name: 'Edit highlight tags in notebook',
        keys: shortcutsMap[ShortcutId.EditHighlightTagsInNotebook],
      },
      [ShortcutId.EditHighlightNoteInNotebook]: {
        id: ShortcutId.EditHighlightNoteInNotebook,
        name: 'Edit highlight note in notebook',
        keys: shortcutsMap[ShortcutId.EditHighlightNoteInNotebook],
      },
      [ShortcutId.OpenNotebookHighlightActionsDropdown]: {
        id: ShortcutId.OpenNotebookHighlightActionsDropdown,
        name: 'Open notebook highlight actions menu',
        keys: shortcutsMap[ShortcutId.OpenNotebookHighlightActionsDropdown],
      },
      [ShortcutId.OpenDocumentNoteActionsDropdown]: {
        id: ShortcutId.OpenDocumentNoteActionsDropdown,
        name: 'Open document note actions menu',
        keys: shortcutsMap[ShortcutId.OpenDocumentNoteActionsDropdown],
      },
      [ShortcutId.ViewNotebookHighlightInDocument]: {
        id: ShortcutId.ViewNotebookHighlightInDocument,
        name: 'View notebook highlight in document',
        keys: shortcutsMap[ShortcutId.ViewNotebookHighlightInDocument],
      },
      [ShortcutId.ViewHighlightInNotebookView]: {
        id: ShortcutId.ViewHighlightInNotebookView,
        name: 'View highlight in notebook view',
        keys: shortcutsMap[ShortcutId.ViewHighlightInNotebookView],
      },
      [ShortcutId.CopyNotebookHighlightText]: {
        id: ShortcutId.CopyNotebookHighlightText,
        name: 'Copy notebook highlight text',
        keys: shortcutsMap[ShortcutId.CopyNotebookHighlightText],
      },
      [ShortcutId.CopyNotebookHighlightNote]: {
        id: ShortcutId.CopyNotebookHighlightNote,
        name: 'Copy notebook highlight note',
        keys: shortcutsMap[ShortcutId.CopyNotebookHighlightNote],
      },
      [ShortcutId.DeleteHighlightInNotebook]: {
        id: ShortcutId.DeleteHighlightInNotebook,
        name: 'Delete highlight in notebook',
        keys: shortcutsMap[ShortcutId.DeleteHighlightInNotebook],
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Document Commands': {
      [ShortcutId.ToggleDocMoreActions]: {
        id: ShortcutId.ToggleDocMoreActions,
        name: 'Open more document actions menu',
        keys: shortcutsMap[ShortcutId.ToggleDocMoreActions],
      },
      [ShortcutId.SwitchRightSidebarTab]: {
        id: ShortcutId.SwitchRightSidebarTab,
        name: 'Cycle forward through document sidebar tabs',
        keys: shortcutsMap[ShortcutId.SwitchRightSidebarTab],
      },
      [ShortcutId.SwitchRightSidebarTabBackward]: {
        id: ShortcutId.SwitchRightSidebarTabBackward,
        name: 'Cycle backward through document sidebar tabs',
        keys: shortcutsMap[ShortcutId.SwitchRightSidebarTabBackward],
      },
      [ShortcutId.ShowDocMetadata]: {
        id: ShortcutId.ShowDocMetadata,
        name: 'Edit document metadata',
        keys: shortcutsMap[ShortcutId.ShowDocMetadata],
      },
      [ShortcutId.NextMetadataItem]: {
        id: ShortcutId.NextMetadataItem,
        name: 'Next metadata item',
        keys: shortcutsMap[ShortcutId.NextMetadataItem],
      },
      [ShortcutId.PreviousMetadataItem]: {
        id: ShortcutId.PreviousMetadataItem,
        name: 'Previous metadata item',
        keys: shortcutsMap[ShortcutId.PreviousMetadataItem],
      },
      [ShortcutId.ResetReadingProgress]: {
        id: ShortcutId.ResetReadingProgress,
        name: 'Reset reading progress',
        keys: shortcutsMap[ShortcutId.ResetReadingProgress],
      },
      [ShortcutId.EnableOrViewPublicLink]: {
        id: ShortcutId.EnableOrViewPublicLink,
        name: 'Enable public annotated version',
        keys: shortcutsMap[ShortcutId.EnableOrViewPublicLink],
      },
      [ShortcutId.Share]: {
        id: ShortcutId.Share,
        name: 'Copy original URL to clipboard',
        keys: shortcutsMap[ShortcutId.Share],
      },
      [ShortcutId.ShareReaderLink]: {
        id: ShortcutId.ShareReaderLink,
        name: 'Copy Reader URL to clipboard',
        keys: shortcutsMap[ShortcutId.ShareReaderLink],
      },
      [ShortcutId.OpenOriginalDoc]: {
        id: ShortcutId.OpenOriginalDoc,
        name: 'Open original URL in new tab',
        keys: shortcutsMap[ShortcutId.OpenOriginalDoc],
      },
      [ShortcutId.CopyText]: {
        id: ShortcutId.CopyText,
        name: 'Copy focused paragraph or highlight',
        keys: shortcutsMap[ShortcutId.CopyText],
      },
      [ShortcutId.SaveLinkToReader]: {
        id: ShortcutId.SaveLinkToReader,
        name: 'Save focused link',
        keys: shortcutsMap[ShortcutId.SaveLinkToReader],
      },
      [ShortcutId.ExportCopyToClipboard]: {
        id: ShortcutId.ExportCopyToClipboard,
        name: 'Copy document annotations to clipboard',
        keys: shortcutsMap[ShortcutId.ExportCopyToClipboard],
      },
      [ShortcutId.ExportDownloadAnnotations]: {
        id: ShortcutId.ExportDownloadAnnotations,
        name: 'Download document annotations',
        keys: shortcutsMap[ShortcutId.ExportDownloadAnnotations],
      },
      [ShortcutId.FindInDocument]: {
        id: ShortcutId.FindInDocument,
        name: 'Find in document',
        keys: shortcutsMap[ShortcutId.FindInDocument],
      },
      [ShortcutId.DownloadDocument]: {
        id: ShortcutId.DownloadDocument,
        name: 'Download document',
        keys: shortcutsMap[ShortcutId.DownloadDocument],
      },
      [ShortcutId.PrintDocument]: {
        id: ShortcutId.PrintDocument,
        name: 'Print document',
        keys: shortcutsMap[ShortcutId.PrintDocument],
      },
      [ShortcutId.ChangeSidebarContentType]: {
        id: ShortcutId.ChangeSidebarContentType,
        name: 'Change document content type',
        keys: shortcutsMap[ShortcutId.ChangeSidebarContentType],
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Audio Controls': {
      [ShortcutId.PlayOrPause]: {
        id: ShortcutId.PlayOrPause,
        name: 'Play / pause text-to-speech',
        keys: shortcutsMap[ShortcutId.PlayOrPause],
      },
      [ShortcutId.Stop]: {
        id: ShortcutId.Stop,
        name: 'Stop and hide text-to-speech player',
        keys: shortcutsMap[ShortcutId.Stop],
      },
      [ShortcutId.IncreaseVolume]: {
        id: ShortcutId.IncreaseVolume,
        name: 'Increase volume',
        keys: shortcutsMap[ShortcutId.IncreaseVolume],
      },
      [ShortcutId.DecreaseVolume]: {
        id: ShortcutId.DecreaseVolume,
        name: 'Decrease volume',
        keys: shortcutsMap[ShortcutId.DecreaseVolume],
      },
      [ShortcutId.MediaPlay]: {
        id: ShortcutId.MediaPlay,
        name: 'Play text-to-speech',
        keys: shortcutsMap[ShortcutId.MediaPlay],
      },
      [ShortcutId.MediaPause]: {
        id: ShortcutId.MediaPause,
        name: 'Pause text-to-speech audio',
        keys: shortcutsMap[ShortcutId.MediaPause],
      },
      [ShortcutId.MediaStop]: {
        id: ShortcutId.MediaStop,
        name: 'Stop and hide text-to-speech player',
        keys: shortcutsMap[ShortcutId.MediaStop],
      },
      [ShortcutId.MediaPreviousTrack]: {
        id: ShortcutId.MediaPreviousTrack,
        name: 'Skip backwards in text-to-speech audio',
        keys: shortcutsMap[ShortcutId.MediaPreviousTrack],
      },
      [ShortcutId.MediaSeekBackward]: {
        id: ShortcutId.MediaSeekBackward,
        name: 'Skip backwards in text-to-speech audio',
        keys: shortcutsMap[ShortcutId.MediaSeekBackward],
      },
      [ShortcutId.MediaSeekForward]: {
        id: ShortcutId.MediaSeekForward,
        name: 'Skip forwards in text-to-speech audio',
        keys: shortcutsMap[ShortcutId.MediaSeekForward],
      },
      [ShortcutId.MediaNextTrack]: {
        id: ShortcutId.MediaNextTrack,
        name: 'Skip forwards in text-to-speech audio',
        keys: shortcutsMap[ShortcutId.MediaNextTrack],
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Audio & Video Controls': {
      [ShortcutId.SlowDownPlayBack]: {
        id: ShortcutId.SlowDownPlayBack,
        name: 'Slow down playback speed',
        keys: shortcutsMap[ShortcutId.SlowDownPlayBack],
      },
      [ShortcutId.SpeedUpPlayback]: {
        id: ShortcutId.SpeedUpPlayback,
        name: 'Speed up playback speed',
        keys: shortcutsMap[ShortcutId.SpeedUpPlayback],
      },
      [ShortcutId.SkipForward]: {
        id: ShortcutId.SkipForward,
        name: 'Skip forward',
        keys: shortcutsMap[ShortcutId.SkipForward],
      },
      [ShortcutId.SkipBackwards]: {
        id: ShortcutId.SkipBackwards,
        name: 'Skip backwards',
        keys: shortcutsMap[ShortcutId.SkipBackwards],
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Video Controls': {
      [ShortcutId.ToggleVideoControls]: {
        id: ShortcutId.ToggleVideoControls,
        name: 'Open video control menu',
        keys: shortcutsMap[ShortcutId.ToggleVideoControls],
      },
      [ShortcutId.ToggleYtAutoScroll]: {
        id: ShortcutId.ToggleYtAutoScroll,
        name: 'Toggle auto-scroll',
        keys: shortcutsMap[ShortcutId.ToggleYtAutoScroll],
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'PDF Controls': {
      [ShortcutId.PdfZoomIn]: {
        id: ShortcutId.PdfZoomIn,
        name: 'Zoom in',
        keys: shortcutsMap[ShortcutId.PdfZoomIn],
      },
      [ShortcutId.PdfZoomOut]: {
        id: ShortcutId.PdfZoomOut,
        name: 'Zoom out',
        keys: shortcutsMap[ShortcutId.PdfZoomOut],
      },
      [ShortcutId.PdfZoomReset]: {
        id: ShortcutId.PdfZoomReset,
        name: 'Reset zoom',
        keys: shortcutsMap[ShortcutId.PdfZoomReset],
      },
      [ShortcutId.PdfRotateLeft]: {
        id: ShortcutId.PdfRotateLeft,
        name: 'Rotate left',
        keys: shortcutsMap[ShortcutId.PdfRotateLeft],
      },
      [ShortcutId.PdfSnapshot]: {
        id: ShortcutId.PdfSnapshot,
        name: 'Start snapshot',
        keys: shortcutsMap[ShortcutId.PdfSnapshot],
      },
      [ShortcutId.PdfJumpToPage]: {
        id: ShortcutId.PdfJumpToPage,
        name: 'Jump to page',
        keys: shortcutsMap[ShortcutId.PdfJumpToPage],
      },
      [ShortcutId.PdfFind]: {
        id: ShortcutId.PdfFind,
        name: 'Find in PDF',
        keys: shortcutsMap[ShortcutId.PdfFind],
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Adding Content': {
      [ShortcutId.OpenSaveDocFromUrlPalette]: {
        id: ShortcutId.OpenSaveDocFromUrlPalette,
        name: 'Add document from URL',
        keys: shortcutsMap[ShortcutId.OpenSaveDocFromUrlPalette],
      },
      [ShortcutId.UploadFile]: {
        id: ShortcutId.UploadFile,
        name: 'Upload file',
        keys: shortcutsMap[ShortcutId.UploadFile],
      },
      [ShortcutId.ManageFeedSubscriptions]: {
        id: ShortcutId.ManageFeedSubscriptions,
        name: 'Add RSS feed from URL',
        keys: shortcutsMap[ShortcutId.ManageFeedSubscriptions],
      },
      [ShortcutId.ToggleRssOrEmailSubscription]: {
        id: ShortcutId.ToggleRssOrEmailSubscription,
        name: 'Subscribe to RSS feed of document',
        keys: shortcutsMap[ShortcutId.ToggleRssOrEmailSubscription],
      },
    },
    Appearance: {
      [ShortcutId.ToggleDarkMode]: {
        id: ShortcutId.ToggleDarkMode,
        name: 'Toggle light/dark mode',
        keys: shortcutsMap[ShortcutId.ToggleDarkMode],
      },
      [ShortcutId.CycleTypeFace]: {
        id: ShortcutId.CycleTypeFace,
        name: 'Cycle typeface',
        keys: shortcutsMap[ShortcutId.CycleTypeFace],
      },
      [ShortcutId.IncreaseFontSize]: {
        id: ShortcutId.IncreaseFontSize,
        name: 'Increase font size',
        keys: shortcutsMap[ShortcutId.IncreaseFontSize],
      },
      [ShortcutId.DecreaseFontSize]: {
        id: ShortcutId.DecreaseFontSize,
        name: 'Decrease font size',
        keys: shortcutsMap[ShortcutId.DecreaseFontSize],
      },
      [ShortcutId.IncreaseLineSpacing]: {
        id: ShortcutId.IncreaseLineSpacing,
        name: 'Increase line spacing',
        keys: shortcutsMap[ShortcutId.IncreaseLineSpacing],
      },
      [ShortcutId.DecreaseLineSpacing]: {
        id: ShortcutId.DecreaseLineSpacing,
        name: 'Decrease line spacing',
        keys: shortcutsMap[ShortcutId.DecreaseLineSpacing],
      },
      [ShortcutId.WidenLineLength]: {
        id: ShortcutId.WidenLineLength,
        name: 'Widen line length',
        keys: shortcutsMap[ShortcutId.WidenLineLength],
      },
      [ShortcutId.NarrowLineLength]: {
        id: ShortcutId.NarrowLineLength,
        name: 'Narrow line length',
        keys: shortcutsMap[ShortcutId.NarrowLineLength],
      },
      [ShortcutId.ToggleZenMode]: {
        id: ShortcutId.ToggleZenMode,
        name: 'Toggle focus mode',
        keys: shortcutsMap[ShortcutId.ToggleZenMode],
      },
      [ShortcutId.FullScreen]: {
        id: ShortcutId.FullScreen,
        name: 'Toggle full screen',
        keys: shortcutsMap[ShortcutId.FullScreen],
      },
      [ShortcutId.HideLeftPanel]: {
        id: ShortcutId.HideLeftPanel,
        name: 'Toggle left panel',
        keys: shortcutsMap[ShortcutId.HideLeftPanel],
      },
      [ShortcutId.HideRightPanel]: {
        id: ShortcutId.HideRightPanel,
        name: 'Toggle right panel',
        keys: shortcutsMap[ShortcutId.HideRightPanel],
      },
      [ShortcutId.HidePanels]: {
        id: ShortcutId.HidePanels,
        name: 'Toggle both panels',
        keys: shortcutsMap[ShortcutId.HidePanels],
      },
    },
    Help: {
      [ShortcutId.OpenShortcutsMenu]: {
        id: ShortcutId.OpenShortcutsMenu,
        name: 'Keyboard shortcuts',
        keys: shortcutsMap[ShortcutId.OpenShortcutsMenu],
      },
    },
  };

  // Remove empty values
  const filteredResult = Object.fromEntries(
    Object.entries(draftResult).filter(([, value]) => Object.keys(value).length),
  );

  return filteredResult;
};

export const documentLocationShortcutIdMap = {
  [DocumentLocation.Archive]: ShortcutId.Archive,
  [DocumentLocation.Feed]: ShortcutId.Feed,
  [DocumentLocation.Later]: ShortcutId.Later,
  [DocumentLocation.New]: ShortcutId.New,
  [DocumentLocation.Shortlist]: ShortcutId.Shortlist,
};

export const useAllShortcutsInUse = () => {
  const shortcutsMap = useShortcutsMap();

  const allShortcuts = useMemo(() => {
    return Object.values(shortcutsMap).reduce((accSet, keys) => {
      return new Set([...accSet, ...keys]);
    }, new Set<string>());
  }, [shortcutsMap]);

  return Array.from(allShortcuts).filter((key) => !keysAllowedToBeInMoreThanOneShortcut.includes(key));
};

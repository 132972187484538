import React from 'react';

import Icon from './Icon';

export default function ArchiveIcon({ className }: { className?: string; }): JSX.Element {
  return (
    <Icon text="Archive">
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3 2.25C2.58579 2.25 2.25 2.58579 2.25 3V6C2.25 6.41421 2.58579 6.75 3 6.75H17C17.4142 6.75 17.75 6.41421 17.75 6V3C17.75 2.58579 17.4142 2.25 17 2.25H3ZM3.75 5.25V3.75H16.25V5.25H3.75Z" />
        <path d="M3 8.25C3.41421 8.25 3.75 8.58579 3.75 9V14.1875C3.75 15.3266 4.67341 16.25 5.8125 16.25H14.1875C15.3266 16.25 16.25 15.3266 16.25 14.1875V9C16.25 8.58579 16.5858 8.25 17 8.25C17.4142 8.25 17.75 8.58579 17.75 9V14.1875C17.75 16.155 16.155 17.75 14.1875 17.75H5.8125C3.84499 17.75 2.25 16.155 2.25 14.1875V9C2.25 8.58579 2.58579 8.25 3 8.25Z" />
        <path d="M7.375 10C7.375 9.58579 7.71079 9.25 8.125 9.25H11.875C12.2892 9.25 12.625 9.58579 12.625 10C12.625 10.4142 12.2892 10.75 11.875 10.75H8.125C7.71079 10.75 7.375 10.4142 7.375 10Z" />
      </svg>
    </Icon>
  );
}

import React from 'react';

import Icon from './Icon';

export default function LargeSkipForwardIcon({
  className,
  text = 'Skip forward',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.001 9.19637C13.001 9.27913 13.0183 9.35562 13.0488 9.42368C13.1272 9.59856 13.293 9.71774 13.4772 9.74431C13.6036 9.76255 13.7387 9.73716 13.8602 9.65618L18.1963 6.76542C18.5791 6.51022 18.5791 5.94771 18.1963 5.69251L13.8602 2.80175C13.4929 2.55692 13.001 2.82018 13.001 3.26157V5.43209C11.6565 5.81325 10.3862 6.4501 9.26399 7.31955C7.18013 8.93405 5.74536 11.2432 5.2209 13.8266C4.69644 16.41 5.11716 19.0959 6.40647 21.3952C7.69579 23.6945 9.76794 25.4543 12.2457 26.3542C14.7234 27.2542 17.4419 27.2344 19.9063 26.2985C22.3707 25.3626 24.417 23.5728 25.6728 21.255C26.9285 18.9372 27.3101 16.2456 26.7481 13.6701C26.2606 11.4358 25.0911 9.41784 23.4141 7.8882C22.9883 7.49978 22.3301 7.59596 21.9837 8.05666C21.6374 8.51737 21.7352 9.16753 22.1523 9.56532C23.4336 10.7873 24.3281 12.3697 24.7089 14.115C25.1642 16.2018 24.855 18.3828 23.8376 20.2608C22.8201 22.1388 21.1621 23.589 19.1653 24.3473C17.1685 25.1056 14.9658 25.1216 12.9582 24.3924C10.9506 23.6632 9.27168 22.2373 8.22701 20.3743C7.18234 18.5113 6.84145 16.3351 7.26639 14.2419C7.69134 12.1487 8.85386 10.2777 10.5423 8.9695C10.6828 8.86066 10.8261 8.75631 10.9721 8.65654L11.001 8.6369C11.6255 8.21367 12.2979 7.87371 13.001 7.62238V9.19637Z"
          fill="currentColor"
        />
        <path
          d="M12.7725 20.5717C12.1367 20.5717 11.7795 20.1842 11.7795 19.5061V14.0629H11.7553L11.1195 14.4928C10.8047 14.6926 10.6352 14.7531 10.4354 14.7531C10.0357 14.7531 9.74512 14.4625 9.74512 14.0629C9.74512 13.7662 9.92676 13.4998 10.284 13.2758L11.2709 12.6158C11.7432 12.3131 12.173 12.1738 12.5787 12.1738C13.2871 12.1738 13.7594 12.6521 13.7594 13.3908V19.5061C13.7594 20.1902 13.4021 20.5717 12.7725 20.5717Z"
          fill="currentColor"
        />
        <path
          d="M18.2277 20.6746C16.5627 20.6746 15.2973 19.7725 15.2973 18.7977C15.2973 18.3254 15.6061 17.9318 16.0965 17.9318C16.3871 17.9318 16.6111 18.0408 16.8412 18.3133C17.259 18.8279 17.6949 19.0701 18.2459 19.0701C19.0451 19.0701 19.6143 18.5312 19.6143 17.732C19.6143 16.9752 19.0693 16.4545 18.3064 16.4545C17.9311 16.4545 17.5314 16.5998 17.1682 16.8602C16.8654 17.0963 16.6535 17.1689 16.4113 17.1689C15.818 17.1689 15.376 16.7512 15.4184 16.1396L15.6182 13.1971C15.6605 12.5492 16.0602 12.2223 16.817 12.2223H20.2561C20.8373 12.2223 21.1643 12.5189 21.1643 13.0275C21.1643 13.5301 20.8434 13.8086 20.2561 13.8086H17.2166L17.0834 15.6008H17.1863C17.477 15.2193 18.1309 14.9711 18.8453 14.9711C20.4437 14.9711 21.5215 16.0549 21.5215 17.6775C21.5215 19.4879 20.2258 20.6746 18.2277 20.6746Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}

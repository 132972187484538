import type { PersistentState } from '../types';
import { isFilteringByFeedTrueAndSingleFeed, isFilteringBySingleFeed } from '../utils/filteredViews';
import { Token, TokenType, TokenValue } from './types';

const getRssFeedName = ({
  rssFeeds,
  valueToken,
}: { rssFeeds: PersistentState['rssFeeds']; valueToken: TokenValue; }) => {
  if (rssFeeds) {
    return rssFeeds[valueToken?.value]?.name || 'No name';
  } else {
    return valueToken?.value || 'No name';
  }
};

export function getUserFriendlyQuery({
  tokens,
  rssFeeds,
}: { tokens: Token[]; rssFeeds: PersistentState['rssFeeds']; }): string {
  let currentIndex = 0;
  let query = '';

  while (currentIndex < tokens.length) {
    const token = tokens[currentIndex];

    switch (token.type) {
      case TokenType.Key: {
        const keyToken = token;
        const operatorToken = tokens[++currentIndex];
        const valueToken = tokens[++currentIndex];

        query += keyToken?.value || '';
        query += operatorToken?.value || '';

        if (keyToken.value === 'rssSource' && valueToken) {
          query += getRssFeedName({ rssFeeds, valueToken: valueToken as TokenValue });
        } else {
          query += valueToken?.value || '';
        }

        currentIndex++;
        break;
      }

      case TokenType.OpenParen:
      case TokenType.CloseParen:
        query += token.value;
        currentIndex++;
        break;

      default:
        query += ` ${token.value} `;
        currentIndex++;
        break;
    }
  }

  // If the query looks like 'feed:true AND rssSource:"01fsps2cjdsprsk6vrc6z39rd8"' or 'rssSource:"01fsps2cjdsprsk6vrc6z39rd8"'
  // We only want to show the RSS feed name.
  if (isFilteringByFeedTrueAndSingleFeed({ filterTokens: tokens })) {
    query = query.replace('feed:true AND rssSource:', '');
  } else if (isFilteringBySingleFeed({ filterTokens: tokens })) {
    query = query.replace('rssSource:', '');
  }

  return query;
}

import React from 'react';

import Icon from './Icon';

export default function CheckmarkIcon({
  className,
  text = '',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="10"
        height="8"
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.53033 2.03033C9.82322 1.73744 9.82322 1.26256 9.53033 0.96967C9.23744 0.676777 8.76256 0.676777 8.46967 0.96967L3.5 5.93934L1.53033 3.96967C1.23744 3.67678 0.762563 3.67678 0.46967 3.96967C0.176777 4.26256 0.176777 4.73744 0.46967 5.03033L2.96967 7.53033C3.26256 7.82322 3.73744 7.82322 4.03033 7.53033L9.53033 2.03033Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}

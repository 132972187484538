import React from 'react';

import Icon from './Icon';

export default function BooksIcon({
  className = '',
  text = 'Book',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11 6.80423C9.9428 6.28906 8.75516 6 7.5 6C6.24484 6 5.0572 6.28906 4 6.80423V17.8042C5.0572 17.2891 6.24484 17 7.5 17C9.1686 17 10.7179 17.5108 12 18.3847C13.2821 17.5108 14.8314 17 16.5 17C17.7552 17 18.9428 17.2891 20 17.8042V6.80423C18.9428 6.28906 17.7552 6 16.5 6C15.2448 6 14.0572 6.28906 13 6.80423V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V6.80423Z" />
      </svg>
    </Icon>
  );
}

import { PDFRotation } from '../../types';

export const getNewPdfRotation = (curr: number, counterClockwise = false) => {
  switch (curr) {
    case PDFRotation.R_270:
      return counterClockwise ? PDFRotation.R_180 : PDFRotation.R_0;
    case PDFRotation.R_180:
      return counterClockwise ? PDFRotation.R_90 : PDFRotation.R_270;
    case PDFRotation.R_90:
      return counterClockwise ? PDFRotation.R_0 : PDFRotation.R_180;
    default:
      return counterClockwise ? PDFRotation.R_270 : PDFRotation.R_90;
  }
};

// PDFTron mobile doesn't support `.setRotation(degree)` :(, it only supports `.rotateClockwise()` and `.rotateCounterClockwise()`
// This helper function calculates how many times we should call `.rotateClockwise()` to achieve the desired rotation.
export const getHowManyTimesPdfShouldRotateClockWise = ({
  pdfRotation,
  newRotation,
}: { pdfRotation: number; newRotation: number; }) => {
  const rotations = [PDFRotation.R_0, PDFRotation.R_90, PDFRotation.R_180, PDFRotation.R_270];
  const currentRotationIndex = rotations.indexOf(pdfRotation);
  const newRotationIndex = rotations.indexOf(newRotation);
  return (newRotationIndex - currentRotationIndex + 4) % 4;
};

import React from 'react';

import { Dialog } from './Dialog';

export const EmptyTrashDialog = ({
  isOpen,
  onConfirm,
  onCancel,
  trashCount,
}: {
  isOpen: boolean;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
  trashCount: number;
}) => {
  if (isOpen) {
    return (
      <Dialog
        id="empty-trash"
        title={`Are you sure you want to permanently delete ${trashCount === 1 ? '1 document' : `all ${trashCount} documents`} in the Trash?`}
        subtitle="You can't undo this action."
        actionTitle="Empty Trash"
        redActionButton
        action={onConfirm}
        cancelAction={onCancel}
      />
    );
  }
  return null;
};

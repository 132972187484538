import React from 'react';

import Icon from './Icon';

export default function StopIcon({
  className,
  text = 'Stop',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="8" height="8" rx="1.5" fill="currentColor" />
      </svg>
    </Icon>
  );
}

import { AnyDocument } from '../types';

export default ({
  rssSourceName,
  siteName,
  originUrl,
}: { rssSourceName?: string; siteName?: AnyDocument['site_name']; originUrl?: string; }):
  | string
  | undefined => {
  return rssSourceName ?? originUrl ?? siteName ?? undefined;
};

import React from 'react';

import Icon from './Icon';

export default function RotateLeftIcon({
  className = '',
  text = 'Views',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.25 14.5455C4.25 15.7633 5.2367 16.75 6.45455 16.75H11.5455C12.7633 16.75 13.75 15.7633 13.75 14.5455V9.45455C13.75 8.2367 12.7633 7.25 11.5455 7.25H6.45455C5.2367 7.25 4.25 8.2367 4.25 9.45455V14.5455ZM6.45455 15.25C6.06512 15.25 5.75 14.9349 5.75 14.5455V9.45455C5.75 9.06512 6.06512 8.75 6.45455 8.75H11.5455C11.9349 8.75 12.25 9.06512 12.25 9.45455V14.5455C12.25 14.9349 11.9349 15.25 11.5455 15.25H6.45455Z" />
        <path d="M11.5303 5.53033C11.8232 5.23744 11.8232 4.76256 11.5303 4.46967L10.8107 3.75H13.8C15.1993 3.75 16.25 4.80232 16.25 6V9C16.25 9.41421 16.5858 9.75 17 9.75C17.4142 9.75 17.75 9.41421 17.75 9V6C17.75 3.88418 15.9351 2.25 13.8 2.25H10.8107L11.5303 1.53033C11.8232 1.23744 11.8232 0.762564 11.5303 0.469669C11.2374 0.176777 10.7626 0.176777 10.4697 0.469669L8.46967 2.46967C8.3238 2.61554 8.25057 2.80656 8.25 2.99775L8.25 3L8.25 3.00225C8.2503 3.10312 8.27051 3.19931 8.30691 3.28709C8.34351 3.37555 8.39776 3.45842 8.46967 3.53033L10.4697 5.53033C10.7626 5.82322 11.2374 5.82322 11.5303 5.53033Z" />
      </svg>
    </Icon>
  );
}

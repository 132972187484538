import ISO6391 from 'iso-639-1';

// ISO 639-1 only supports two-letter codes.
// This is means that only standard versions of languages
// are included. This object contains variants of languages
// that we want to include for TTS purposes, using ISO 639-3.
const customLanguages = {
  wuu: 'Chinese (Wu, Simplified)',
  yue: 'Chinese (Cantonese, Simplified)',
  nl_be: 'Dutch (Belgium)',
};
export default (languageCodeParam: string | undefined): string => {
  let languageCode = languageCodeParam;
  if (!languageCode || languageCode === 'Unknown') {
    return 'Unknown';
  }
  // overrides for TTS supported languages
  if (languageCode === 'fil') {
    // manual override for filipino TG code
    languageCode = 'tl';
  } else if (Object.keys(customLanguages).includes(languageCode)) {
    return customLanguages[languageCode];
  }
  const result = ISO6391.getName(languageCode.split('-')[0]);
  return result === '' ? 'Unknown' : result;
};

export const getLanguageCode = (language: string): string => {
  // This may return nothing for regional languages (English - United States) that aren't in customLanguages.
  const customCode = Object.keys(customLanguages).find((key) => customLanguages[key] === language);
  return customCode ?? ISO6391.getCode(language);
};

export const getAllLanguageCodes = () => {
  return [...ISO6391.getAllCodes(), ...Object.keys(customLanguages)];
};

export const getAllLanguageNames = () => {
  return [...ISO6391.getAllNames(), ...Object.values(customLanguages)].sort();
};

export const validateLanguageCode = (languageCode: string) => {
  return getAllLanguageCodes().includes(languageCode);
};

import React from 'react';

import Icon from './Icon';

export default function LargeSkipBackwardIcon({
  className,
  text = 'Skip backward',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.0001 9.16756V7.59377C19.8788 7.90786 20.7095 8.36039 21.4587 8.94088C23.1472 10.249 24.3097 12.12 24.7346 14.2133C24.9384 15.2169 24.9661 16.2396 24.8245 17.2377C24.6709 18.3212 24.3178 19.3757 23.774 20.3454C22.7294 22.2084 21.0504 23.6343 19.0428 24.3635C17.0352 25.0927 14.8325 25.0767 12.8358 24.3183C10.839 23.56 9.18095 22.1099 8.16349 20.2319C7.27321 18.5886 6.92519 16.7134 7.15714 14.8723C7.16957 14.7737 7.18366 14.6751 7.19942 14.5767C7.22568 14.4128 7.25657 14.2493 7.29213 14.0864C7.67296 12.3411 8.56739 10.7587 9.84873 9.53669C10.0516 9.3432 10.179 9.09 10.2164 8.82794C10.256 8.55114 10.1953 8.26444 10.0173 8.02775C9.671 7.56704 9.01281 7.47087 8.58697 7.85928C6.90997 9.38893 5.74046 11.4069 5.25293 13.6411C5.1942 13.9103 5.14577 14.1807 5.10757 14.4519C4.78019 16.7753 5.20372 19.1508 6.32826 21.2264C7.58399 23.5442 9.63033 25.334 12.0947 26.2699C14.5591 27.2058 17.2776 27.2256 19.7553 26.3256C22.2331 25.4257 24.3052 23.6658 25.5945 21.3665C26.2682 20.1652 26.7047 18.8583 26.8932 17.5157C27.0655 16.2885 27.0306 15.0314 26.7802 13.7977C26.2557 11.2143 24.8209 8.90513 22.7371 7.29063C21.6149 6.42118 20.3446 5.78433 19.0001 5.40318V3.23276C19.0001 2.79138 18.5081 2.52811 18.1409 2.77294L13.8047 5.66371C13.4219 5.91891 13.4219 6.48141 13.8047 6.73661L18.1409 9.62737C18.5081 9.87221 19.0001 9.60894 19.0001 9.16756Z"
          fill="currentColor"
        />
        <path
          d="M12.6357 20.5434C12 20.5434 11.6428 20.1559 11.6428 19.4777V14.0346H11.6186L10.9828 14.4645C10.668 14.6643 10.4984 14.7248 10.2986 14.7248C9.89902 14.7248 9.6084 14.4342 9.6084 14.0346C9.6084 13.7379 9.79004 13.4715 10.1473 13.2475L11.1342 12.5875C11.6064 12.2848 12.0363 12.1455 12.442 12.1455C13.1504 12.1455 13.6227 12.6238 13.6227 13.3625V19.4777C13.6227 20.1619 13.2654 20.5434 12.6357 20.5434Z"
          fill="currentColor"
        />
        <path
          d="M18.091 20.6463C16.426 20.6463 15.1605 19.7441 15.1605 18.7693C15.1605 18.2971 15.4693 17.9035 15.9598 17.9035C16.2504 17.9035 16.4744 18.0125 16.7045 18.285C17.1223 18.7996 17.5582 19.0418 18.1092 19.0418C18.9084 19.0418 19.4775 18.5029 19.4775 17.7037C19.4775 16.9469 18.9326 16.4262 18.1697 16.4262C17.7943 16.4262 17.3947 16.5715 17.0314 16.8318C16.7287 17.068 16.5168 17.1406 16.2746 17.1406C15.6812 17.1406 15.2393 16.7229 15.2816 16.1113L15.4814 13.1687C15.5238 12.5209 15.9234 12.1939 16.6803 12.1939H20.1193C20.7006 12.1939 21.0275 12.4906 21.0275 12.9992C21.0275 13.5018 20.7066 13.7803 20.1193 13.7803H17.0799L16.9467 15.5725H17.0496C17.3402 15.191 17.9941 14.9428 18.7086 14.9428C20.307 14.9428 21.3848 16.0266 21.3848 17.6492C21.3848 19.4596 20.0891 20.6463 18.091 20.6463Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}

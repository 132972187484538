import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { recoverDocument } from 'shared/foreground/actions/recoverDocument';
import type { DocumentLocation } from 'shared/types';

import RecoverIcon from './icons/20StrokeRecover';
import Tooltip from './Tooltip';
import styles from './TrashActionButton.module.css';

interface TrashActionButtonProps {
  docId: string;
  docDocumentLocation?: DocumentLocation | null;
  actionDelay?: number;
  beforeAction?: (isMovingTo: string) => void;
  afterAction?: (...args: unknown[]) => void;
}

export default function TrashActionButton({
  docId,
  docDocumentLocation,
  actionDelay = 0,
  beforeAction,
  afterAction,
}: TrashActionButtonProps): JSX.Element {
  const [isRecovering, setIsRecovering] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (!isRecovering) {
        return;
      }
      setIsRecovering(false);

      await recoverDocument(docId, (doc) => {
        history.push(`/read/${doc.id}`);
      });

      if (afterAction) {
        afterAction();
      }
    }, actionDelay);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isRecovering, afterAction, actionDelay, docId, docDocumentLocation, history]);

  const handleRecover = () => {
    if (beforeAction) {
      beforeAction('recover');
    }
    setIsRecovering(true);
  };

  const tooltipText = 'Recover Document';

  return (
    <Tooltip content={tooltipText}>
      <button
        className={`${styles.actionButton} ${styles.documentLocationActionButton}`}
        tabIndex={-1}
        onClick={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
          handleRecover();
        }}
        type="button"
      >
        <RecoverIcon text={tooltipText} />
      </button>
    </Tooltip>
  );
}

import get from 'lodash/get';
import pick from 'lodash/pick';
import { ulid } from 'ulid';

import { FilteredView, JobType, UserEvent } from '../../../types';
import { globalState, updateState } from '../../models';
import { removeFilteredViewFromState, saveFilteredViewToState } from '../../stateUpdateHelpers';
import { createToast } from '../../toasts.platform';
import { StateUpdateOptionsWithoutEventName } from '../../types';
import { queueJob } from './jobs';

export const mergeFilteredViews = async ({
  viewIds,
  viewName,
  options,
}: {
  viewIds: FilteredView['id'][];
  viewName: string;
  options: StateUpdateOptionsWithoutEventName & { showToast?: boolean; };
}): Promise<void> => {
  const updateResult = await updateState(
    (state) => {
      const queries = [];

      for (const viewId of viewIds) {
        if (state.persistent.filteredViews) {
          queries.push(`(${state.persistent.filteredViews[viewId].query})`);
        }

        removeFilteredViewFromState(state, viewId);
      }

      const newView = {
        name: viewName,
        description: '',
        query: queries.join(' OR '),
      };

      saveFilteredViewToState(state, newView);
    },
    { ...options, eventName: 'filtered-views-merged' },
  );

  createToast({
    content: 'Views merged',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const saveFilteredView = async (
  filter: Omit<FilteredView, 'id' | 'created' | 'sortRules'>,
  pathname: string,
  options: StateUpdateOptionsWithoutEventName & { showToast?: boolean; },
): Promise<string> => {
  const id = ulid().toLowerCase();
  const filterWithId = {
    ...filter,
    id,
  };

  const updateResult = await updateState(
    (state) => {
      saveFilteredViewToState(state, filterWithId, pathname);
    },
    { ...options, eventName: 'filtered-view-saved' },
  );

  if (options.showToast !== false) {
    createToast({
      content: 'Filtered view saved',
      category: 'success',
      undoableUserEventId: (updateResult.userEvent as UserEvent).id,
    });
  }
  return id;
};

export const updateFilteredViews = async (
  filteredViews: FilteredView[],
  options: StateUpdateOptionsWithoutEventName & {
    showToast?: boolean;
  },
): Promise<void> => {
  const propsChangedToTriggerBundleUpdates = ['name', 'description', 'extraData.bundleCoverImageURL'];
  const viewsIdsToUpdateBundle = new Set<string>();

  const updateResult = await updateState(
    (state) => {
      for (const filteredView of filteredViews) {
        // E.g when removing an associated feed from a view the query could be empty
        // if that was the only thing the query was doing. So we make sure we delete that view.
        // An RSS folder can have an empty query, in that case we don't delete it.
        if (filteredView.query === '' && !filteredView.rssFolderId) {
          removeFilteredViewFromState(state, filteredView.id);
        } else {
          const currentView = state.persistent.filteredViews[filteredView.id];
          const updatedFilteredView = {
            ...currentView,
            ...pick(filteredView, [
              'name',
              'description',
              'icon',
              'query',
              'sortRules',
              'splitBy',
              'isUnpinned',
              'showCountBadge',
              'sharedAsBundle',
              'extraData',
            ]),
          };

          if (updatedFilteredView.sharedAsBundle) {
            propsChangedToTriggerBundleUpdates.forEach((prop) => {
              if (get(currentView, prop) !== get(updateFilteredView, prop)) {
                viewsIdsToUpdateBundle.add(filteredView.id);
              }
            });
          }

          if (!updatedFilteredView.splitBy) {
            delete updatedFilteredView.splitBy;
          }

          state.persistent.filteredViews[filteredView.id] = updatedFilteredView;
        }
      }
    },
    { ...options, eventName: 'filtered-view-updated' },
  );

  if (options.showToast) {
    createToast({
      content: 'Filtered view updated',
      category: 'success',
      undoableUserEventId: (updateResult.userEvent as UserEvent).id,
    });
  }

  Array.from(viewsIdsToUpdateBundle).forEach((viewId) => {
    queueJob({
      jobType: JobType.UpdateBundle,
      jobArguments: { filtered_view_id: viewId, enabled: true },
      options: { userInteraction: 'click' },
    });
  });
};

export const updateFilteredView = async (
  filteredView: FilteredView,
  options: StateUpdateOptionsWithoutEventName & {
    showToast?: boolean;
  },
): Promise<void> => {
  updateFilteredViews([filteredView], options);
};

export const updatePartialFilteredView = async (
  partialFilteredView: { id: FilteredView['id']; } & Partial<Omit<FilteredView, 'id'>>,
  options: StateUpdateOptionsWithoutEventName & {
    showToast?: boolean;
  },
): Promise<void> => {
  const filteredView = globalState.getState().persistent.filteredViews[partialFilteredView.id];
  updateFilteredViews(
    [
      {
        ...filteredView,
        ...partialFilteredView,
      },
    ],
    options,
  );
};

export const updateFilteredViewsOrder = async (
  filteredViews: Pick<FilteredView, 'id' | 'order'>[],
  options: StateUpdateOptionsWithoutEventName & {
    showToast?: boolean;
  },
): Promise<void> => {
  const updateResult = await updateState(
    (state) => {
      filteredViews.forEach((filteredView) => {
        state.persistent.filteredViews[filteredView.id].order = filteredView.order;
      });
    },
    { ...options, eventName: 'filtered-view-updated' },
  );

  if (options.showToast) {
    createToast({
      content: 'Filtered view order updated',
      category: 'success',
      undoableUserEventId: (updateResult.userEvent as UserEvent).id,
    });
  }
};

export const removeFilteredViews = async (
  viewIds: FilteredView['id'][],
  options: StateUpdateOptionsWithoutEventName & { showToast?: boolean; },
): Promise<void> => {
  const viewsIdsToDisableBundle: string[] = [];
  let toastContent = '';

  const updateResult = await updateState(
    (state) => {
      for (const viewId of viewIds) {
        const view = state.persistent.filteredViews[viewId];
        if (view?.sharedAsBundle) {
          viewsIdsToDisableBundle.push(viewId);
        }

        if (!toastContent) {
          if (viewIds.length === 1) {
            toastContent = view.rssFolderId ? 'Folder deleted' : 'Filtered view removed';
          } else {
            toastContent = `${viewIds.length} filtered views removed`;
          }
        }

        removeFilteredViewFromState(state, viewId);
      }
    },
    { ...options, eventName: 'filtered-view-removed' },
  );

  for (const viewIdToDisableBundle of viewsIdsToDisableBundle) {
    queueJob({
      jobType: JobType.UpdateBundle,
      jobArguments: { filtered_view_id: viewIdToDisableBundle, enabled: false },
      options: { userInteraction: 'click' },
    });
  }

  if (options.showToast) {
    createToast({
      content: toastContent,
      category: 'success',
      undoableUserEventId: (updateResult.userEvent as UserEvent).id,
    });
  }
};

export const removeFilteredView = async (
  viewId: FilteredView['id'],
  options: StateUpdateOptionsWithoutEventName & { showToast?: boolean; },
): Promise<void> => {
  removeFilteredViews([viewId], { ...options, showToast: options.showToast !== false });
};
